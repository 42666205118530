import { createSignalAction, createSimpleCreator } from "shared/reduxUtils"
const BASE = "BROKER_DASHBAORD"

// Genral
export const getAgentDetailsAction = createSignalAction(BASE, "GET_AGENT_DETAILS")
export const createDealAction = createSignalAction(BASE, "CREATE_DEAL")
export const updateStarredStatusInStateAction = createSimpleCreator(BASE, "UPDATE_STARRED_STATUS")
//Onboarding
export const getOrientationInfoAction = createSignalAction(BASE, "GET_ORIENTATION_DETAILS")
export const getBrandingInfoAction = createSignalAction(BASE, "GET_BRANDING_DETAILS")
export const setBusinessCard = createSimpleCreator(BASE, "SET_BUSINESS_CARD")
export const setYardSign = createSimpleCreator(BASE, "SET_YARD_SIGN")
export const getBrandingAssetsAction = createSignalAction(BASE, "GET_BRANDING_ASSETS")
export const setFileUploading = createSimpleCreator(BASE, "SET_FILE_UPLOADING")
export const updateOrientationInfoAction = createSignalAction(BASE, "UPDATE_ORIENTATION_DETAILS")
export const createOrientationInfoAction = createSignalAction(BASE, "CREATE_ORIENTATION_DETAILS")
export const createBrandingInfoAction = createSignalAction(BASE, "CREATE_BRANDING_DETAILS")
export const updateBrandingInfoAction = createSignalAction(BASE, "UPDATE_BRANDING_DETAILS")
export const createBrandingAssetsAction = createSignalAction(BASE, "CREATE_BRANDING_ASSETS")
export const updateBrandingAssetsAction = createSignalAction(BASE, "UPDATE_BRANDING_ASSETS")
export const getSetupProfileAction = createSignalAction(BASE, "GET_SETUP_PROFILE_DETAILS")
export const updateSetupProfileAction = createSignalAction(BASE, "UPDATE_SETUP_PROFILE_DETAILS")
export const getContractStatusAction = createSignalAction(BASE, "GET_CONTRACT_STATUSES")
// TC
export const searchClientByText = createSignalAction(BASE, "SEARCH_CLIENT")
export const getSecureIdForTCAction = createSignalAction(BASE, "GET_AGENT_SECURE_ID")
export const getFileForTCAction = createSignalAction(BASE, "GET_FILES_TC")
export const updateUserSettingsAction = createSignalAction(BASE, "UPDATE_USER_SETTINGS")
export const saveStepsForProductAction = createSignalAction(BASE, "SAVE_STEPS")
export const saveFileTypeChangeAction = createSignalAction(BASE, "SAVE_FILE_TYPE")
export const updateDealStatusAction = createSignalAction(BASE, "UPDATE_DEAL_STATUS")
export const getPendingRequestsAction = createSignalAction(BASE, "GET_PENDING_REQUESTS")
export const changeStatusRequestAction = createSignalAction(BASE, "CHANGE_REQUEST_STATUS")
export const changeLinkAction = createSignalAction(BASE, "CHANGE_LINK")
export const getRARTeamListAction = createSignalAction(BASE, "GET_RAR_TEAM_LIST")
export const getTeamDetailsActions = createSignalAction(BASE, "GET_RAR_TEAM_DETAILS")
export const getBrokerTeamsOverviewAction = createSignalAction(BASE, "GET_TEAMS_PAGE_OVERVIEW")
export const getBrokerageTeamDownloadAction = createSignalAction(BASE, "GET_TEAMS_PAGE_CSV_EXPORT")
export const tcOverviewAction = createSignalAction(BASE, "TC_OVERVIEW_DETAILS")
export const tcOverviewListingAction = createSignalAction(BASE, "TC_OVERVIEW_LISTING_DETAILS")

export const tcDataDownloadAction = createSignalAction(BASE, "TC_DATA_DOWNLOAD")
export const getTCListAction = createSignalAction(BASE, "GET_TC_LIST")
export const updateTCAgentAction = createSignalAction(BASE, "UPDATE_TC_LIST")
export const updateAgentOptedTCAction = createSignalAction(BASE, "UPDATE_AGENT_OPTED_TC")
export const getTCAgentAssignedAction = createSignalAction(BASE, "GET_TC_AGENT_ASSIGNED")
export const sendSlackDealStatusChangeAction = createSignalAction(BASE, "SEND_SLACK_NOTIFICATION")
export const getDealStatusTimelineAction = createSignalAction(BASE, "GET_DEAL_STATUS_TIMELINE")
export const toggleTimelineDrawerAction = createSimpleCreator(BASE, "TOGGLE_TIMELINE_DRAWER")
export const setSelectedId = createSimpleCreator(BASE, "SET_SELECTED_VENDOR")
export const toggleEscrowDrawerAction = createSimpleCreator(BASE, "TOGGLE_ESCROW_DRAWER")
export const toggleUploadImageDrawerAction = createSimpleCreator(BASE, "TOGGLE_IMAGE_UPLOAD_DRAWER")
export const setDealIdToUploadAction = createSimpleCreator(BASE, "DEAL_ID_FOR_UPLOAD")
export const setAllListingImagesAction = createSimpleCreator(BASE, "SET_ALL_LISTING_IMAGES")
export const createAllListingImagesAction = createSignalAction(BASE, "CREATE_ALL_LISTING_IMAGES")
export const getVendorDetailsAction = createSignalAction(BASE, "GET_VENDOR_DETAILS")
export const setEscrowEdit = createSimpleCreator(BASE, "EDIT_ESCROW_DETAILS")
export const setAllVendors = createSimpleCreator(BASE, "SET_VENDOR_DETAILS")
export const createVendorDetailsAction = createSignalAction(BASE, "CREATE_VENDOR_DETAILS")
export const updateVendorDetailsAction = createSignalAction(BASE, "UPDATE_VENDOR_DETAILS")
export const selectVendorDetailsAction = createSignalAction(BASE, "SELECT_VENDOR_DETAILS")
export const fetchDealTimelineAction = createSignalAction(BASE, "FETCH_DEAL_TIMELINE")
export const updateDealTimelineAction = createSignalAction(BASE, "UPDATE_DEAL_TIMELINE")
export const toggleTimelineChecklistAction = createSimpleCreator(BASE, "TOGGLE_TIMELINE_CHECKLIST")
export const setDealIdTimelineAction = createSimpleCreator(BASE, "SET_DEALID_TIMELINE")
export const setRadiusFeeAction = createSimpleCreator(BASE, "SET_RADIUS_FEE")

// Global Pages
export const getAllDealsListingActions = createSignalAction(BASE, "GET_ALL_DEALS_LISTING")
export const getAssetsDashboardAction = createSignalAction(BASE, "GET_ASSETS_DASHBOARD")
export const createNewGlobalAssetAction = createSignalAction(BASE, "CREATE_NEW_GLOBAL_ASSET")
export const deleteGlobalAssetAction = createSignalAction(BASE, "DELETE_GLOBAL_ASSETS")
export const getResourcesListAction = createSignalAction(BASE, "RESOURCES_LIST")
export const createResourceAction = createSignalAction(BASE, "CREATE_RESOURCES")
export const updateResourcesAction = createSignalAction(BASE, "UPDATE_RESOURCES")
export const deleteResourcesAction = createSignalAction(BASE, "DELETE_RESOURCE")
export const sortTCListAction = createSimpleCreator(BASE, "TC_SORT_BY")
export const updateCommsionDemandAction = createSignalAction(BASE, "UPDATE_COMMISSION_DEMAND")
export const deleteCommissionDemandActions = createSignalAction(BASE, "DELETE_COMMISSION_DEMAND")
export const allDealsListingFilterActions = createSimpleCreator(BASE, "ALL_DEALS_LISTING_FILTERS")
export const showQueryMoreForDealsActions = createSimpleCreator(BASE, "SHOULD_QUERY_MORE_DEALS")
export const showTCLoaderActions = createSimpleCreator(BASE, "SHOW_TC_LOADER")
// Subscription
export const resetSelectionsAction = createSimpleCreator(BASE, "RESET_PLAN_SELECTIONS")
export const toggleIsBusyAction = createSimpleCreator(BASE, "TOGGLE_IS_BUSY")
export const getAgentsSubscriptionAction = createSignalAction(BASE, "GET_AGENT_SUBSCRIPTION")
export const getAgentAddonsActions = createSignalAction(BASE, "GET_AGENT_ADDONS")
export const changePlanDurationAction = createSimpleCreator(BASE, "CHANGE_PLAN_DURATION")
export const getPlanbenefitsAction = createSignalAction(BASE, "GET_PLAN_BENEFITS")
export const selectPlanAction = createSimpleCreator(BASE, "SELECT_PLAN")
export const selectAddonAction = createSimpleCreator(BASE, "SELECT_ADDON")
export const checkoutBrokerPlanAction = createSignalAction(BASE, "CHECKOUT_BROKER_PLAN")
export const checkoutBrokerPlanV2Action = createSignalAction(BASE, "CHECKOUT_BROKERAGE_PLAN_V2")
export const checkCouponAction = createSignalAction(BASE, "CHECK_COUPON")
export const removeCouponCodeAction = createSimpleCreator(BASE, "REMOVE_COUPON")
export const getUSStatesAction = createSignalAction(BASE, "GET_US_STATES")
export const changeStatesForAgentAction = createSignalAction(BASE, "CHANGE_STATES_FOR_AGENTS")
export const cancelSubscriptionAction = createSignalAction(BASE, "CANCEL_SUBSCRIPTION")
export const getAgentStatesActions = createSignalAction(BASE, "GET_AGENT_STATES")
export const getSubsribedAddonsAction = createSignalAction(BASE, "GET_SUBSCRIBED_ADDONS")
export const updateSubscriptionAction = createSignalAction(BASE, "UPDATE_SUBSCRIPTION")
export const updateSubscriptionV2Action = createSignalAction(BASE, "UPDATE_SUBSCRIPTION_V2")
export const getAgentCardDetailsAction = createSignalAction(BASE, "GET_AGENT_CARD_DETAILS")
export const clearAddonsAction = createSimpleCreator(BASE, "CLEAR_ADDONS")
export const getAgentTypesAction = createSignalAction(BASE, "GET_AGENT_TYPES_LIST")
export const getUserAgentTypeAction = createSignalAction(BASE, "GET_USER_AGENT_TYPE")
export const changeUserAgentTypeAction = createSignalAction(BASE, "CHANGE_USER_AGENT_TYPE")

// export const handleAssignTeamOrTeamLeadAction = createSignalAction(BASE, "ASSIGN_TEAM_OR_TEAM_LEAD")
// Assets (Agent Specific)
export const getAgentAssetsActions = createSignalAction(BASE, "GET_AGENT_ASSETS")
export const updateAgentAssetsActions = createSignalAction(BASE, "UPDATE_AGENT_ASSETS")
export const uploadFilesAction = createSignalAction(BASE, "UPLOAD_ASSETS")
export const uploadDocsToBucketAction = createSignalAction(BASE, "UPLOAD_ORIENTATION_FILES")
export const uploadDocsToBucketBrandingAction = createSignalAction(BASE, "UPLOAD_BRANDING_FILES")
export const uploadDocsForListingAction = createSignalAction(BASE, "UPLOAD_LISTING_IMAGES")
export const resetListingImages = createSimpleCreator(BASE, "RESET_LISTING_IMAGE_UPLOAD")
export const resetDealId = createSimpleCreator(BASE, "RESET_DEAL_ID_FOR_UPLOAD")
export const toggleIsUploadingFileAction = createSimpleCreator(BASE, "IS_UPLOADING_FILE")
export const updateAssetsFormLinksAction = createSimpleCreator(BASE, "UPDATE_ASSETS_FORM")

//Docusign
export const createDocusignTemplateAction = createSignalAction(BASE, "CREATE_DOCUSIGN_TEMPLATE")
export const getDocusignTemplateAction = createSignalAction(BASE, "GET_DOCUSIGN_TEMPLATE")
export const setTemplateListAction = createSimpleCreator(BASE, "SETTING_TEMPLATE_LIST")
export const setTemplateInvalidAction = createSimpleCreator(BASE, "SETTING_INVALID_TEMPLATE")
export const deleteTemplateListAction = createSimpleCreator(BASE, "DELETE_TEMPLATE_LIST")
export const deleteDocusignTemplateAction = createSignalAction(BASE, "DELETE_TEMPLATE")
export const getListingTotalCommonTemplate = createSignalAction(BASE, "GET_LISTING_COUNT_COMMON_TEMPLATE")

export const createOffersDocusignTemplate = createSignalAction(BASE, "CREATE_OFFERS_DOCUSIGN_TEMPLATE")
export const getOffersDocusignTemplate = createSignalAction(BASE, "GET_OFFERS_DOCUSIGN_TEMPLATE")
export const deleteOffersDocusignTemplate = createSignalAction(BASE, "DELETE_OFFERS_TEMPLATE_LIST")

export const getOffersTotalCommonTemplate = createSignalAction(BASE, "GET_OFFERS_COUNT_COMMON_TEMPLATE")

// Roster Page
export const getAgentForRosterPageAction = createSignalAction(BASE, "GET_AGENT_FOR_ROSTER_PAGE")
export const rosterPageDataDownloadAction = createSignalAction(BASE, "ROSTER_PAGE_DATA_DOWNLOAD")
export const getAgentYearEndWrapAction = createSignalAction(BASE, "GET_AGENT_YEAR_END_WRAP")
// Agent Pipeline Page
export const getAgentPipelinePageAction = createSignalAction(BASE, "GET_AGENT_PIPELINE_PAGE")
export const getReferralSourcesAction = createSignalAction(BASE, "GET_REFERRAL_SOURCES")
export const getCurrentCitiesServedAction = createSignalAction(BASE, "GET_CITIES_SERVED")
export const getNeighbourHoodFromCitiesAction = createSignalAction(BASE, "GET_NEIGHBOURHOODS")
//CURRENT//
export const deleteNeighbourhoodAction = createSignalAction(BASE, "DELETE_NEIGHBOURHOOD")
export const saveAgentServedNeighbourhoodAction = createSignalAction(BASE, "SAVE_NEIGHBOURHOODS")
export const saveCitiesServedForAgentAction = createSignalAction(BASE, "SAVE_CITIES")
//ROSTER TEAM PAGE
export const deleteRARTeamAction = createSignalAction(BASE, "DELETE_RAR_TEAM")

export const getListingCoverSheet = createSignalAction(BASE, "GET_LISTING_COVERSHEET")
export const getContractsDetails = createSignalAction(BASE, "GET_CONTRACTS_DETAILS")
export const createListingCoverSheet = createSignalAction(BASE, "CREATE_LISTING_COVERSHEET")
export const updateListingCoverSheet = createSignalAction(BASE, "UPDATE_LISTING_COVERSHEET")
export const getListingTypeAction = createSignalAction(BASE, "FETCH_LISTING_TYPE")

export const setDealToView = createSimpleCreator(BASE, "SET_LISTING_TO_VIEW")
export const setEscrowIdToEdit = createSimpleCreator(BASE, "SET_ESCROW_ID_EDIT")
export const setLoaderOnAgentInfo = createSimpleCreator(BASE, "SET_LOADER_ON_AGENT_INFO")
export const setListingStateAction = createSimpleCreator(BASE, "SET_LISTING_STATE")
export const setDealAgentId = createSimpleCreator(BASE, "SET_DEAL_AGENT_ID")
export const resetListings = createSimpleCreator(BASE, "RESET_LISTINGS")
export const setTotalListingCount = createSimpleCreator(BASE, "SET_TOTAL_LISTING_COUNT")

export const getAgentBrokerageInfoAction = createSignalAction(BASE, "GET_AGENT_BROKERAGE_INFO")
export const postAgentBrokerageInfoAction = createSignalAction(BASE, "POST_AGENT_BROKERAGE_INFO")
export const updateAgentBrokerageInfoAction = createSignalAction(BASE, "UPDATE_AGENT_BROKERAGE_INFO")

export const addCreditCardDetailsAction = createSignalAction(BASE, "ADD_CARD_DETAILS")
export const getAgentBrokerageInfoMetadataAction = createSignalAction(BASE, "GET_BROKER_METADATA")
export const selectedSubscriptionPlansAction = createSimpleCreator(BASE, "SELECTED_PLANS")

export const addOnPlanIdsAction = createSimpleCreator(BASE, "ADD_ON_PLAN_IDS")

//ICA RELATED ACTIONS
export const setCurrentAgentViewedAction = createSimpleCreator(BASE, "SET_CURRENT_AGENT_ID")
export const toggleICADetailsModalAction = createSimpleCreator(BASE, "TOGGLE_ICA_MODAL")
export const fetchICAListingsAction = createSignalAction(BASE, "FETCH_ICA_LISTING")
export const fetchAgentICADetailsAction = createSignalAction(BASE, "GET_ICA_DETAILS")
export const triggerSenderViewDocuSignAction = createSignalAction(BASE, "TRIGGER_SENDER_VIEW")
export const triggerRecieverViewDocuSignAction = createSignalAction(BASE, "TRIGGER_RECIEVER_VIEW")
export const updateICADocumentStatusAction = createSignalAction(BASE, "UPDATE_ICA_DOC_STATUS")
export const toggleTeamAgreementTypeAction = createSimpleCreator(BASE, "TOGGLE_TEAM_AGREEMENT_TYPE")
export const mergeReviewAndSendDocumentsAction = createSignalAction(BASE, "MERGE_REVIEW_SEND_DOC")
export const uploadICAAction = createSignalAction(BASE, "UPLOAD_ICA")
export const uploadTeamAgreementAction = createSignalAction(BASE, "UPLOAD_TEAM_AGREEMENT")
export const inviteWithoutSubscriptionAction = createSignalAction(BASE, "INVITE_WITHOUT_SUBSCRIPTION")
export const fetchTeamAgreementsAction = createSignalAction(BASE, "FETCH_TEAM_AGREEMENTS")
export const toggleUploadModeForICAAction = createSimpleCreator(BASE, "TOGGLE_UPLOAD_MODE_FOR_ICA")
export const uploadICAFileAction = createSignalAction(BASE, "UPLOAD_ICA")
export const sendOnboardingEmailAction = createSignalAction(BASE, "SEND_ONBOARDING_EMAIL")
export const copyOnboardingURLAction = createSignalAction(BASE, "COPY_ONBOARDING_URL")
export const fetchFullySignedICAAction = createSignalAction(BASE, "FETCH_FULLY_SIGNED_ICA")
export const uploadFullySignedICAAction = createSignalAction(BASE, "UPLOAD_FULLY_SIGNED_ICA")
export const deleteUploadedTeamAgreementAction = createSignalAction(BASE, "DELETE_UPLOADED_TEAM_AGREEMENT")
export const toggleFullySignedICAUploadUIAction = createSimpleCreator(BASE, "TOGGLE_FULLY_SIGNED_UPLOAD")
export const disableOtherOptionsICAActions = createSimpleCreator(BASE, "DISABLE_ICA_OPTIONS")
export const saveTeamAgreementAction = createSignalAction(BASE, "SAVE_TEAM_AGREEMENT")
export const currentlySelectedTypeAction = createSimpleCreator(BASE, "CURRENT_TYPE_ICA_FLOW")
export const toggleMissingTeamMessageAction = createSimpleCreator(BASE, "MISSING_TEAM_MESSAGE")
export const triggerTemplateEditViewAction = createSignalAction(BASE, "TRIGGER_TEMPLATE_EDIT_VIEW")
export const showUpdateLiveDotAction = createSimpleCreator(BASE, "SHOW_UPDATE_DOT")
export const getTemplateDownloadURLAction = createSignalAction(BASE, "TEMPLATE_DOWNLOAD")
export const setTeamIDFromURLAction = createSimpleCreator(BASE, "SET_TEAM_ID_FROM_URL")
export const toggleVoidAndDeleteModalAction = createSimpleCreator(BASE, "TOGGLE_VOID_AND_DELETE_MODAL")
export const triggerVoidAndDeleteAction = createSignalAction(BASE, "TRIGGER_VOID_AND_DELETE")
export const triggerDeleteFullyLoadedICAAction = createSignalAction(BASE, "DELETE_FULLY_LOADED_ICA")

//STRIPE RELATED
export const saveStripeTokenAction = createSignalAction(BASE, "SAVE_STRIPE_TOKEN")
export const getTentativeBrokerageSubscriptionDetailsAction = createSignalAction(BASE, "TENTATIVE_BROKERAGE_DETAILS")
export const fetchRecruiterAndAsmInfoAction = createSignalAction(BASE, "FETCH_RECRUITER_ASM")
export const updateRecruiterAndAsmInfoAction = createSignalAction(BASE, "UPDATE_RECRUITER_ASM")
export const fetchAgentAsmRecruiterInfoAction = createSignalAction(BASE, "FETCH_AGENT_RECRUITER_ASM")

export const uploadCSVToS3Action = createSignalAction(BASE, "UPLOAD_CSV_FOR_CONTRACTS")
export const uploadS3LocationAction = createSignalAction(BASE, "UPLOAD_TO_LOCATION")
export const toggleModalViewAction = createSimpleCreator(BASE, "TOGGLE_MODAL_VIEW")

export const highlightSelectedAddonsAction = createSimpleCreator(BASE, "HIGH_SELECTED_ADDONS")

export const saveListingStepsAction = createSignalAction(BASE, "SAVE_LISTINGS_STEPS")
export const toggleTeamCreateModalAction = createSimpleCreator(BASE, "CREATE_TEAM_MODAL_TOGGLE")
export const inviteWithoutSubscriptionPayloadCreationAction = createSimpleCreator(
  BASE, "CREATE_INVITE_WITHOUT_SUBSCRIPTION_PAYLOAD"
)
export const toggleInviteWithoutSubscriptionModalAction = createSimpleCreator(BASE, "TOGGLE_INVITE_SUBSCRIPTION_MODAL")
export const handleCouponFieldChangeAction = createSimpleCreator(BASE, "HANDLE_COUPON_FIELD")
export const clearCurrentCouponDataAction = createSimpleCreator(BASE, "CLEAR_CURRENT_COUPON_DATA")
export const updateSubscriptionDateAction = createSignalAction(BASE, "UPDATE_SUBSCRIPTION_DATE")
export const uploadSellerProfileImageAction = createSignalAction(BASE, "UPLOAD_SELLER_PROFILE_IMAGE")
export const setIsCreatingDealAction = createSimpleCreator(BASE, "SET_CREATE_DEAL")
export const uploadW2FormAction = createSignalAction(BASE, "UPLOAD_W2_DOCUMENT")
