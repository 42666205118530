import {
  call, put, throttle, takeLatest, select, delay,
} from "redux-saga/effects"
import { isSuccess } from "services/Utils"
import copyToClipBoard from "copy-to-clipboard"
import {
  get, isEmpty, lowerCase, take,
} from "lodash"
import { notify } from "react-notify-toast"
import { Modal, notification, message } from "antd"
import { format } from "date-fns"
import axios from "axios"
import root from "window-or-global"
import cookie from "react-cookies"
import { fetchSubscriptionsAction, fetchUserReferralSettingsAction } from "container/Agent/actions"
import { uploadImageAPI } from "container/Academy/api"
import { applyAutoReferOutSettings as userUserSettingsAPI } from "container/Agent/api"
import { toast } from "react-toastify"
import LogRocket from "services/LogRocket"
import {
  getRequestsListAPI,
  changeRequestStatusAPI,
  changeLinkAPI,
  getRARTeamListAPI,
  getDealsAPI,
  getSecureIdAPI,
  getAgentDetailsAPI,
  createDealAPI,
  editDealAPI,
  getBenefitsAPI,
  checkOutBrokerPlanAPI,
  checkCouponAPI,
  getUSStatesAPI,
  saveAgentStatesAPI,
  getAssetDashboardAssetsAPI,
  createNewGlobalAssetAPI,
  getAgentAssetsAPI,
  updateAgentAssetAPI,
  getBrokerAgentAPI,
  cancelPlanAPI,
  getSubscribedAddonsAPI,
  deleteGlobalAssetAPI,
  updateSubscriptionAPI,
  getAgentCardDetailsAPI,
  getAgentTypesAPI,
  fetchUserAgentTypeAPI,
  changeUserAgentTypeAPI,
  searchClientAPI,
  getTCFileAPI,
  saveStepsAPI,
  getAgentPipelineAPI,
  getReferralSourcesAPI,
  getCurrentCitiesServedAPI,
  getNeighbourHoodFromCitiesAPI,
  saveNeighbourhoodsAPI,
  deleteNeighbourhoodAPI,
  tcOverviewAPI,
  deleteRARTeamAPI,
  getAllResourcesAPI,
  createResourceAPI,
  updateResourceAPI,
  deleteResourceAPI,
  updateCommissionDemandAPI,
  deleteCommissionDemandAPI,
  downloadTcDataAPI,
  getTCListAPI,
  updateTCtoDealAPI,
  updateAgentOptedTCAPI,
  getTCAgentAssignedAPI,
  brokerDataDownloadAPI,
  slackNotifyDealStatusChangeAPI,
  getOrientationInfoAPI,
  updateOrientationInfoAPI,
  createOrientationInfoAPI,
  getDealStatusTimelineAPI,
  getBrandingInfoAPI,
  getBrandingAssetsAPI,
  createBrandingInfoAPI,
  updateBrandingInfoAPI,
  createBrandingAssetsAPI,
  updateBrandingAssetsAPI,
  getSetupProfileInfoAPI,
  updateSetupProfileInfoAPI,
  getListingCoverSheetAPI,
  createAllListingImagesAPI,
  createListingCoverSheetAPI,
  updateListingCoverSheetAPI,
  getListingTypeAPI,
  getVendorDetailsAPI,
  createVendorDetailsAPI,
  updateVendorDetailsAPI,
  getContractStatusAPI,
  selectVendorDetailsAPI,
  saveFileTypeChangeAPI,
  getContractsDetailsAPI,
  createDocusignTemplateAPI,
  getDocusignTemplateAPI,
  deleteDocusignTemplateAPI,
  fetchDealTimelineAPI,
  updateDealTimelineAPI,
  createOffersDocusignTemplateAPI,
  getOffersDocusignTemplateAPI,
  getOffersTotalCommonTemplateAPI,
  deleteOffersDocusignTemplateAPI,
  getAgentBrokerageInfoAPI,
  postAgentBrokerageInfoAPI,
  updateAgentBrokerageInfoAPI,
  getBrokerTeamsOverviewAPI,
  getBrokerageTeamDownloadAPI,
  addCreditCardDetailsAPI,
  getAgentBrokerageInfoMetadataAPI,
  fetchICAListingsAPI,
  fetchAgentICADetailsAPI,
  triggerDocusignSenderViewAPI,
  triggerDocusignRecieverViewAPI,
  modifyICAStatusAPI,
  mergeReviewSendAPI,
  uploadICAAPI,
  uploadTeamAgreementAPI,
  inviteWithoutSubscriptionAPI,
  fetchTeamAgreementsAPI,
  saveStripeTokenAPI,
  fetchRecruiterAndAsmInfoAPI,
  getTentativeBrokerageSubscriptionDetailsAPI,
  updateRecruiterAndAsmInfoAPI,
  fetchAgentAsmRecruiterInfoAPI,
  sendOnboardingEmailAPI,
  getOnboardingURLAPI,
  fetchFullySignedICAAPI,
  uploadFullySignedICAAPI,
  saveTeamAgreementAPI,
  triggerTemplateEditViewAPI,
  getTemplateDownloadURLAPI,
  uploadS3LocationAPI,
  //listings
  saveListingStepsAPI,
  tcOverviewListingAPI,
  triggerVoidAndDeleteAPI,
  getListingTotalCommonTemplateAPI,
  triggerDeleteFullyLoadedICAAPI,
  updateSubscriptionDateAPI,
  uploadW2FormAPI,
  getAgentYearWrapAPI,
} from "./api"
import {
  getCurrentPlan,
  createCurrentSelectedPlansMap,
  getAddons,
} from "./selector"
import * as BrokerDashboardActions from "./actions"

function* handleSubscribedAddons(action) {
  try {
    const { agentId } = action.data
    const secureId = yield getSecureIdForAgent(agentId)
    const res = yield call(getSubscribedAddonsAPI, secureId)
    if (isSuccess(res)) {
      const { response } = res.data
      if (response && response.length > 0) {
        for (let i = 0; i < response.length; i++) {
          yield put(BrokerDashboardActions.selectAddonAction.call(response[i]))
          yield put(BrokerDashboardActions.highlightSelectedAddonsAction.call(response[i]))
        }
      }
      yield put(BrokerDashboardActions.getSubsribedAddonsAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getSubsribedAddonsAction.failure(error))
  }
}

function* handleGetPendingRequests(action) {
  try {
    const res = yield call(getRequestsListAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getPendingRequestsAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getPendingRequestsAction.failure(error))
  }
}

function* handleStatusChange(action) {
  try {
    const { payload, callback } = action.data
    const res = yield call(changeRequestStatusAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.changeStatusRequestAction.success(response))
      callback()
    }
  } catch (error) {
    yield put(BrokerDashboardActions.changeStatusRequestAction.failure(error))
  }
}

function* handleLinkChange(action) {
  try {
    const { payload, callback } = action.data
    const res = yield call(changeLinkAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.changeLinkAction.success(response))
      callback()
    }
  } catch (error) {
    yield put(BrokerDashboardActions.changeLinkAction.failure(error))
  }
}

function* handleRarTeamList(action) {
  try {
    const {
      skip = 0,
      limit = 300,
      fromTeamCreatedDate,
      toTeamCreatedDate,
      minTeamMembers,
      maxTeamMembers,
      minPastProduction,
      maxPastProduction,
      primarySaleRepresentative,
      secondarySaleRepresentative,
      pastBrokerageName,
      state,
    } = action.data
    let query = ""
    query = `?skip=${skip}&limit=${limit}&noparam=false`
    if (fromTeamCreatedDate) {
      query += `&fromTeamCreatedDate=${fromTeamCreatedDate}`
    }
    if (toTeamCreatedDate) {
      query += `&toTeamCreatedDate=${toTeamCreatedDate}`
    }
    if (minTeamMembers) {
      query += `&minTeamMembers=${minTeamMembers}`
    }
    if (maxTeamMembers) {
      query += `&maxTeamMembers=${maxTeamMembers}`
    }
    if (minPastProduction) {
      query += `&minPastProduction=${minPastProduction}`
    }
    if (maxPastProduction) {
      query += `&maxPastProduction=${maxPastProduction}`
    }
    if (primarySaleRepresentative) {
      query += `&primarySaleRepresentative=${primarySaleRepresentative}`
    }
    if (secondarySaleRepresentative) {
      query += `&secondarySaleRepresentative=${secondarySaleRepresentative}`
    }
    if (pastBrokerageName) {
      query += `&pastBrokerageName=${pastBrokerageName}`
    }
    if (state) {
      query += `&state=${state}`
    }
    const res = yield call(getRARTeamListAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getRARTeamListAction.success(response))
      console.log("response", response)
      /**
       *
       * if (response && Object.keys(response).length > 0) {
      //   const {
      //     is_custom_team_agreement,
      //   } = response || {}

      //   if (is_custom_team_agreement === 0) {
      //     yield put(BrokerDashboardActions.toggleTeamAgreementTypeAction.call(2))
      //   } else if (is_custom_team_agreement === 1) {
      //     yield put(BrokerDashboardActions.toggleTeamAgreementTypeAction.call(1))
      //   } else if (!is_custom_team_agreement) {
      //     yield put(BrokerDashboardActions.toggleTeamAgreementTypeAction.call(3))
      //   }
      // }
       */
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getRARTeamListAction.failure(error))
  }
}

function* handleTeamsPageDataDownload(action) {
  try {
    const {
      skip = 0,
      limit = 300,
      fromTeamCreatedDate,
      toTeamCreatedDate,
      minTeamMembers,
      maxTeamMembers,
      minPastProduction,
      maxPastProduction,
      primarySaleRepresentative,
      secondarySaleRepresentative,
      pastBrokerageName,
      state,
    } = action.data
    let query = ""
    query = `?skip=${skip}&limit=${limit}&noparam=false`
    if (fromTeamCreatedDate) {
      query += `&fromTeamCreatedDate=${fromTeamCreatedDate}`
    }
    if (toTeamCreatedDate) {
      query += `&toTeamCreatedDate=${toTeamCreatedDate}`
    }
    if (minTeamMembers) {
      query += `&minTeamMembers=${minTeamMembers}`
    }
    if (maxTeamMembers) {
      query += `&maxTeamMembers=${maxTeamMembers}`
    }
    if (minPastProduction) {
      query += `&minPastProduction=${minPastProduction}`
    }
    if (maxPastProduction) {
      query += `&maxPastProduction=${maxPastProduction}`
    }
    if (primarySaleRepresentative) {
      query += `&primarySaleRepresentative=${primarySaleRepresentative}`
    }
    if (secondarySaleRepresentative) {
      query += `&secondarySaleRepresentative=${secondarySaleRepresentative}`
    }
    if (pastBrokerageName) {
      query += `&pastBrokerageName=${pastBrokerageName}`
    }
    if (state) {
      query += `&state=${state}`
    }

    const res = yield call(getBrokerageTeamDownloadAPI, query)
    const { data } = res
    const blob = new Blob([data], { type: "text/csv;charset=utf-8;" })
    const link = document.createElement("a")
    link.setAttribute("href", URL.createObjectURL(blob))
    const currentDate = new Date()
    link.setAttribute("download", `${currentDate.getTime()}_${format(currentDate, "MMM_dd_yyyy")}.csv`)
    document.body.appendChild(link)
    link.click()
    yield put(BrokerDashboardActions.getBrokerageTeamDownloadAction.success(data))
  } catch (e) {
    yield put(BrokerDashboardActions.getBrokerageTeamDownloadAction.failure())
  }
}

function* handleTeamsPageOverview(action) {
  try {
    const {
      fromTeamCreatedDate,
      toTeamCreatedDate,
      minTeamMembers,
      maxTeamMembers,
      minPastProduction,
      maxPastProduction,
      primarySaleRepresentative,
      secondarySaleRepresentative,
      pastBrokerageName,
      state,
    } = action.data
    let query = "?"
    if (fromTeamCreatedDate) {
      query += `&fromTeamCreatedDate=${fromTeamCreatedDate}`
    }
    if (toTeamCreatedDate) {
      query += `&toTeamCreatedDate=${toTeamCreatedDate}`
    }
    if (minTeamMembers) {
      query += `&minTeamMembers=${minTeamMembers}`
    }
    if (maxTeamMembers) {
      query += `&maxTeamMembers=${maxTeamMembers}`
    }
    if (minPastProduction) {
      query += `&minPastProduction=${minPastProduction}`
    }
    if (maxPastProduction) {
      query += `&maxPastProduction=${maxPastProduction}`
    }
    if (primarySaleRepresentative) {
      query += `&primarySaleRepresentative=${primarySaleRepresentative}`
    }
    if (secondarySaleRepresentative) {
      query += `&secondarySaleRepresentative=${secondarySaleRepresentative}`
    }
    if (pastBrokerageName) {
      query += `&pastBrokerageName=${pastBrokerageName}`
    }
    if (state) {
      query += `&state=${state}`
    }

    const res = yield call(getBrokerTeamsOverviewAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getBrokerTeamsOverviewAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getBrokerTeamsOverviewAction.failure(error))
  }
}

// function* handleRarTeamList(action) {
//   try {
//     const res = yield call(getRARTeamListAPI)
//     if (isSuccess(res)) {
//       const { response } = res.data
//       yield put(BrokerDashboardActions.getRARTeamListAction.success(response))
//     }
//   } catch (error) {
//     yield put(BrokerDashboardActions.getRARTeamListAction.failure(error))
//   }
// }

function* handleRARTeamDelete(action) {
  try {
    const { teamId } = action.data
    const res = yield call(deleteRARTeamAPI, teamId)
    if (isSuccess(res)) {
      notify.show("Team Deleted!", "success", 5000)
      window.setTimeout(() => { window.location.reload() }, 1000)
      yield put(BrokerDashboardActions.deleteRARTeamAction.success(res))
    }
  } catch (error) {
    notify.show("Remove all Team Members and Try Again", "error", 5000)
    yield put(BrokerDashboardActions.deleteRARTeamAction.failure(error))
  }
}

function* handleAgentTypesList(action) {
  try {
    const res = yield call(getAgentTypesAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getAgentTypesAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getAgentTypesAction.failure(error))
  }
}

function* handleUserAgentTypeList(action) {
  try {
    const { agentId } = action.data
    const res = yield call(fetchUserAgentTypeAPI, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getUserAgentTypeAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getUserAgentTypeAction.failure(error))
  }
}

function* handleChangeUserAgentTypeList(action) {
  try {
    const {
      agentId,
      userTypeId,
    } = action.data
    const payload = {
      agentId,
      userTypeId,
    }
    const res = yield call(changeUserAgentTypeAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.changeUserAgentTypeAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.changeUserAgentTypeAction.failure(error))
  }
}

function* handleBrandingInfo(action) {
  try {
    const {
      agentId,
    } = action.data
    const res = yield call(getBrandingInfoAPI, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getBrandingInfoAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getBrandingInfoAction.failure(error))
  }
}

function* handleBrandingAssets(action) {
  try {
    const { agentId } = action.data
    const secureId = yield getSecureIdForAgent(agentId)
    const res = yield call(getBrandingAssetsAPI, secureId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getBrandingAssetsAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getBrandingAssetsAction.failure(error))
  }
}

function* handleOrientationInfo(action) {
  try {
    const {
      agentId,
    } = action.data
    const res = yield call(getOrientationInfoAPI, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getOrientationInfoAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getOrientationInfoAction.failure(error))
  }
}

function* handleUpdateOrientationInfo(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    const res = yield call(updateOrientationInfoAPI, payload)
    if (isSuccess(res)) {
      yield put(BrokerDashboardActions.updateOrientationInfoAction.success(res.data && res.data.response))
      if (callback) { callback() }
    }
  } catch (e) {
    yield put(BrokerDashboardActions.updateOrientationInfoAction.failure(e))
  }
}

function* handleCreateOrientationInfo(action) {
  try {
    const {
      payload,
      callUpdateApi,
    } = action.data
    const res = yield call(createOrientationInfoAPI, payload)

    if (isSuccess(res)) {
      yield put(BrokerDashboardActions.createOrientationInfoAction.success(res.data && res.data.response))

      if (callUpdateApi) {
        yield call(updateOrientationInfoAPI, payload)
      }
    }
  } catch (e) {
    yield put(BrokerDashboardActions.createOrientationInfoAction.failure(e))
  }
}

function* handleCreateAllListingImages(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    const res = yield call(createAllListingImagesAPI, payload)
    if (isSuccess(res)) {
      yield put(BrokerDashboardActions.createAllListingImagesAction.success(res.data && res.data.response))
      callback()
    }
  } catch (e) {
    yield put(BrokerDashboardActions.createAllListingImagesAction.failure(e))
  }
}

function* handleCreateBrandingInfo(action) {
  try {
    const {
      payloadInfo,
    } = action.data
    const res = yield call(createBrandingInfoAPI, payloadInfo)
    if (isSuccess(res)) {
      yield put(BrokerDashboardActions.createBrandingInfoAction.success(res.data && res.data.response))
    }
  } catch (e) {
    yield put(BrokerDashboardActions.createBrandingInfoAction.failure(e))
  }
}

function* handleCreateDocusignTemplate(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    const res = yield call(createDocusignTemplateAPI, payload)
    if (isSuccess(res)) {
      yield put(BrokerDashboardActions.createDocusignTemplateAction.success(res.data && res.data.response))
      callback()
    }
  } catch (e) {
    yield put(BrokerDashboardActions.createDocusignTemplateAction.failure(e))
  }
}

function* handleDeleteDocusignTemplate(action) {
  try {
    const {
      id,
      callback,
    } = action.data
    const res = yield call(deleteDocusignTemplateAPI, id)
    if (isSuccess(res)) {
      yield put(BrokerDashboardActions.deleteDocusignTemplateAction.success(res.data && res.data.response))
      callback()
    }
  } catch (e) {
    yield put(BrokerDashboardActions.deleteDocusignTemplateAction.failure(e))
  }
}

function* handleUpdateBrandingInfo(action) {
  try {
    const {
      payloadInfo,
    } = action.data
    const res = yield call(updateBrandingInfoAPI, payloadInfo)
    if (isSuccess(res)) {
      yield put(BrokerDashboardActions.updateBrandingInfoAction.success(res.data && res.data.response))
    }
  } catch (e) {
    yield put(BrokerDashboardActions.updateBrandingInfoAction.failure(e))
  }
}

function* handleCreateBrandingAssets(action) {
  try {
    const {
      payloadAssets,
    } = action.data
    const res = yield call(createBrandingAssetsAPI, payloadAssets)
    if (isSuccess(res)) {
      yield put(BrokerDashboardActions.createBrandingAssetsAction.success(res.data && res.data.response))
    }
  } catch (e) {
    yield put(BrokerDashboardActions.createBrandingAssetsAction.failure(e))
  }
}

function* handleUpdateBrandingAssets(action) {
  try {
    const {
      payloadAssets,
    } = action.data
    const res = yield call(updateBrandingAssetsAPI, payloadAssets)
    if (isSuccess(res)) {
      yield put(BrokerDashboardActions.updateBrandingAssetsAction.success(res.data && res.data.response))
    }
  } catch (e) {
    yield put(BrokerDashboardActions.updateBrandingAssetsAction.failure(e))
  }
}

// function* handleAssignTeamOrTeamLead(action) {
//   try {
//     const {
//       agent_id,
//       is_admin,
//       brokerage_team_id
//     } = action.data
//     const payload = {
//       agent_id,
//       is_admin,
//       brokerage_team_id,
//     }
//     const res = yield call(changeRARTeamAPI, payload)
//     if(isSuccess(res)) {
//       const { response } = res.data
//       yield put(BrokerDashboardActions.handleAssignTeamOrTeamLeadAction.success(response))
//     }
//   } catch (error) {
//     yield put(BrokerDashboardActions.handleAssignTeamOrTeamLeadAction.failure(error))
//   }
// }

//get deal and listings
function* handleGetDeals(action) {
  try {
    const {
      type,
      type_deal,
      skip = 0,
      limit = 300,
      agentId,
      dealId,
      agentEmail = "",
      monthly = true,
      clientType,
      teamId,
      searchId,
      sourceType,
      planCategory,
      agent_state,
      property_state,
      city,
      tc_id,
      listingAddress,
      transaction_type,
      radius_transaction_type,
      fromAcceptance,
      toAcceptance,
      fromLicenseTransferredDate,
      toLicenseTransferredDate,
      fromListing,
      toListing,
      dealType,
      viewContract,
      fromSubmission,
      toSubmission,
      sortData,
      startDate,
      endDate,
      isMerge,
      isShowActiveListing,
      primarySaleRepresentative,
      secondarySaleRepresentative,
      agentType,
      isDateOfSubNA,
      isDateOfContractNA,
      transactionType,
      listingId,
      callback,
      fromDealCreated,
      toDealCreated
      //offset = 0,
    } = action.data
    let query = ""
    query = `?skip=${skip}&limit=${limit}&noparam=false`
    if (transactionType) {
      query += `&transactionType=${transactionType}`
    }
    if (type === "global") {
      if (clientType) {
        query += `&clientType=${clientType}`
      }
      if (teamId) {
        query += `&teamId=${teamId}`
      }
      if (sourceType) {
        query += `&sourceType=${sourceType}`
      }
      if (searchId) {
        query += `&agentId=${searchId}`
      }
      if (planCategory) {
        query += `&plan=${planCategory}`
      }
      if (agent_state) {
        query += `&agent_state=${agent_state}`
      }
      if (property_state) {
        query += `&property_state=${property_state}`
      }
      if (tc_id) {
        query += `&tc_id=${tc_id}`
      }
      if (listingAddress) {
        query += `&listingAddress=${listingAddress}`
      }
      if (transaction_type) {
        query += `&transaction_type=${transaction_type}`
      }
      if (radius_transaction_type) {
        query += `&radius_transaction_type=${radius_transaction_type}`
      }
      if (fromAcceptance) {
        query += `&fromAcceptance=${fromAcceptance}`
      }
      if (toAcceptance) {
        query += `&toAcceptance=${toAcceptance}`
      }
      if (fromLicenseTransferredDate) {
        query += `&fromLicenseTransferredDate=${fromLicenseTransferredDate}`
      }
      if (toLicenseTransferredDate) {
        query += `&toLicenseTransferredDate=${toLicenseTransferredDate}`
      }
      if (fromSubmission) {
        query += `&fromSubmission=${fromSubmission}`
      }
      if (toDealCreated) {
        query += `&toDealCreated=${toDealCreated}`
      }
      if (fromDealCreated) {
        query += `&fromDealCreated=${fromDealCreated}`
      }
      if (toSubmission) {
        query += `&toSubmission=${toSubmission}`
      }
      if (fromListing) {
        query += `&fromListing=${fromListing}`
      }
      if (toListing) {
        query += `&toListing=${toListing}`
      }
      if (primarySaleRepresentative) {
        query += `&primarySaleRepresentative=${primarySaleRepresentative}`
      }
      if (secondarySaleRepresentative) {
        query += `&secondarySaleRepresentative=${secondarySaleRepresentative}`
      }
      if (agentType) {
        query += `&agentType=${agentType}`
      }
      if (isDateOfSubNA) {
        query += `&isDateOfSubNA=${isDateOfSubNA}`
      }
      if (isDateOfContractNA) {
        query += `&isDateOfContractNA=${isDateOfContractNA}`
      }
      if (sortData) {
        if (sortData.createdAtSortOrder) {
          query += `&createdAtSortOrder=${sortData.createdAtSortOrder}`
        } else if (sortData.rowCreatedAtSortOrder) {
          query += `&rowCreatedAtSortOrder=${sortData.rowCreatedAtSortOrder}`
        }
      }
    }
    if (dealType) {
      query += `&dealType=${dealType}`
    }
    if (viewContract || viewContract === 0) {
      query += `&viewContract=${viewContract}`
    }
    if (type === "agent") {
      query += `&agentId=${agentId}`
    }
    if (startDate) {
      query += `&fromClosed=${startDate}`
    }
    if (city) {
      query += `&city=${city}`
    }
    if (endDate) {
      query += `&toClosed=${endDate}`
    }
    if (transactionType === "Listing") {
      if (type_deal === "deal") {
        query += `&listingId=${listingId}`
      }
    }
    if (type_deal === "deal" && transactionType !== "Listing") {
      query += `&dealId=${dealId}`
    }
    const oldTcListing = yield select(state => state.brokerDashboard.dealsListingResponse.data)
    const savedQuery = yield select(state => state.brokerDashboard.allDealsListingFilter)
    const showQueryMoreForDeals = yield select(state => state.brokerDashboard.showQueryMoreForDeals)
    if (savedQuery.split("&noparam=false")[1] === query.split("&noparam=false")[1] && !showQueryMoreForDeals) {
      return
    }
    yield put(BrokerDashboardActions.showQueryMoreForDealsActions.call(true))
    yield put(BrokerDashboardActions.allDealsListingFilterActions.call(query))
    yield put(BrokerDashboardActions.showTCLoaderActions.call(true))
    const res = yield call(getDealsAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      if (callback) callback(response)
      if (!response.length) {
        yield put(BrokerDashboardActions.showQueryMoreForDealsActions.call(false))
      }
      if (isMerge) {
        yield put(BrokerDashboardActions.getAllDealsListingActions.success([
          ...oldTcListing,
          ...response,
        ]))
      } else {
        yield put(BrokerDashboardActions.getAllDealsListingActions.success(response))
      }
    }
    yield put(BrokerDashboardActions.showTCLoaderActions.call(false))
  } catch (error) {
    yield put(BrokerDashboardActions.getAllDealsListingActions.failure(error))
    yield put(BrokerDashboardActions.showTCLoaderActions.call(false))
  }
}

function* handleSaveFileTypeChange(action) {
  try {
    const {
      dealId, payload, callback, isToListing,
    } = action.data
    const user = cookie.load("user")
    const res = yield call(saveFileTypeChangeAPI, dealId, payload)
    if (isSuccess(res)) {
      yield put(BrokerDashboardActions.saveFileTypeChangeAction.success(res.data && res.data.response))
      let stageObject = []
      if (isToListing === "invalid") {
        callback()
        return
      }
      if (isToListing === null) {
        stageObject = [
          {
            deal_id: dealId,
            step: "incomplete",
            admin_name: user.twilio_client_name,
          },
        ]
        yield put(BrokerDashboardActions.saveStepsForProductAction.request({ payload: stageObject[0], callback }))
      } else {
        stageObject = [
          {
            deal_id: dealId,
            step: isToListing,
            admin_name: user.twilio_client_name,
          },
        ]
        yield put(BrokerDashboardActions.saveStepsForProductAction.request({ payload: stageObject[0], callback }))
      }
    }
  } catch (e) {
    yield put(BrokerDashboardActions.saveFileTypeChangeAction.failure(e))
  }
}

function* getSecureIdForAgent(agentId) {
  try {
    const secureRes = yield call(getSecureIdAPI, agentId)
    if (isSuccess(secureRes)) {
      const secureId = get(secureRes, "data.response.secure_id")
      return secureId
    }
  } catch (error) {
    return null
  }
}

function* handleGetAgent(action) {
  try {
    LogRocket.initialize()
    const { agentId } = action.data
    const secureId = yield getSecureIdForAgent(agentId)
    const userRes = yield call(getAgentDetailsAPI, secureId)
    if (isSuccess(userRes)) {
      const { response } = userRes.data
      yield put(BrokerDashboardActions.getAgentDetailsAction.success(response))
      console.log(response.profile_name)
      yield put(BrokerDashboardActions.getCurrentCitiesServedAction.request({
        username: response.profile_name || "",
      }))
    }
  } catch (error) {
  }
}

function* handleGetAgentBrokerageInfo(action) {
  try {
    const { agentId } = action.data
    const res = yield call(getAgentBrokerageInfoAPI, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getAgentBrokerageInfoAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getAgentBrokerageInfoAction.failure(error))
  }
}

function* handlePostAgentBrokerageInfo(action) {
  try {
    const { payload } = action.data
    const res = yield call(postAgentBrokerageInfoAPI, payload)
    if (isSuccess(res)) {
      yield put(BrokerDashboardActions.postAgentBrokerageInfoAction.success(res.data && res.data.response))
    }
  } catch (e) {
    yield put(BrokerDashboardActions.postAgentBrokerageInfoAction.failure(e))
  }
}

function* handleUpdateAgentBrokerageInfo(action) {
  try {
    const { payload, agentId, callback } = action.data
    const res = yield call(updateAgentBrokerageInfoAPI, agentId, payload)
    if (isSuccess(res)) {
      message.success("Details updated!")
      yield put(BrokerDashboardActions.updateAgentBrokerageInfoAction.success(res.data && res.data.response))
      callback()
    }
  } catch (e) {
    message.error("Something went wrong, please check the subscription details")
    yield put(BrokerDashboardActions.updateAgentBrokerageInfoAction.failure(e))
  }
}

function* handleCreateDeal(action) {
  try {
    const {
      payload, history, isEdit, callback, callbackVendors,
    } = action.data
    console.log(payload)
    let res
    const currentFileType = payload.file_type === "new_listing"
    const user = cookie.load("user")
    const currentListingType = payload.active_listing_status
    if (isEdit) {
      res = yield call(editDealAPI, isEdit, payload)
    } else {
      res = yield call(createDealAPI, payload)
    }
    if (callback) {
      callback()
    }
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.createDealAction.success(response))
      /*For loop the yield to save stages*/
      /*
      0: "basic_info"
      1: "transaction_details"
      2: "transaction_coordination"
      3: "sign_post_installation"
      4: "upload_files"
      */
      const isSkipCompleteCall = (
        payload.step === "audit_verified"
        || payload.step === "commission_verified"
        || payload.step === "closed"
      )
      const deal_id = payload.id || response.deal_id
      if (callbackVendors) {
        callbackVendors(deal_id)
      }
      if (!isEdit && !isSkipCompleteCall) {
        let step = "submitted"
        if (currentFileType) {
          step = "active_listing"
        }
        const stageObject = [
          {
            deal_id,
            step,
            admin_name: user.twilio_client_name,
          },
        ]
        yield put(BrokerDashboardActions.saveStepsForProductAction.request({ payload: stageObject[0] }))
      } else if (!isSkipCompleteCall && payload.file_type !== "new_listing" && !isEdit) {
        yield put(BrokerDashboardActions.saveStepsForProductAction.request({ payload: { deal_id, step: "submitted", admin_name: user.twilio_client_name } }))
      }
      yield put(BrokerDashboardActions.setIsCreatingDealAction.call(false))
      history.push("/broker/tc/list")
    }
  } catch (error) {
    yield put(BrokerDashboardActions.setIsCreatingDealAction.call(false))
    if (error.status === 400) {
      notification.error({
        message: "Duplicate Details!",
        description: error.data.error.message,
        duration: 5,
        placement: "top",
      })
    }
    if (error.status === 404) {
      notification.error({
        message: "Invalid Transaction Details",
        description: "Value for Agent Commission cannot be negative. Please adjust Radius Fee or Commission Values",
        duration: 5,
        placement: "top",
      })
    }
    yield put(BrokerDashboardActions.createDealAction.failure(error))
    const messageMessage = get(error, "response.data.error.message")
    notify.show(messageMessage, "error")
  }
}

function* handleBenefits(action) {
  try {
    const {
      planId,
      agentId,
    } = action.data
    let query = `?plan_id=${planId}`
    if (agentId) {
      query = `${query}&agent_id=${agentId}`
    }
    const res = yield call(getBenefitsAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getPlanbenefitsAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getPlanbenefitsAction.failure(error))
  }
}

function* handleGetAgentCardDetails(action) {
  try {
    const { agentId } = action.data
    const secureId = yield getSecureIdForAgent(agentId)
    const res = yield call(getAgentCardDetailsAPI, secureId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getAgentCardDetailsAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getAgentCardDetailsAction.failure(error))
  }
}

function* handleGetVendorDetails(action) {
  try {
    const { agentId, dealId } = action.data
    const res = yield call(getVendorDetailsAPI, agentId, dealId)
    if (isSuccess(res)) {
      let vendorId = null
      const { response } = res.data
      if (res.data && res.data.response.selected_vendor_id) {
        vendorId = res.data.response.selected_vendor_id
      }
      yield put(BrokerDashboardActions.getVendorDetailsAction.success(response))
      yield put(BrokerDashboardActions.setSelectedId.call(vendorId))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getVendorDetailsAction.failure(error))
  }
}

function* handleCreateVendorDetails(action) {
  try {
    const {
      vendors,
      agentId,
      dealId,
    } = action.data
    const res = yield call(createVendorDetailsAPI, agentId, dealId, vendors)
    if (isSuccess(res)) {
      yield put(BrokerDashboardActions.createVendorDetailsAction.success(res.data && res.data.response))
    }
  } catch (e) {
    yield put(BrokerDashboardActions.createVendorDetailsAction.failure(e))
  }
}

function* handleUpdateVendorDetails(action) {
  try {
    const {
      vendors,
      agentId,
      vendorId,
    } = action.data
    const res = yield call(updateVendorDetailsAPI, agentId, vendorId, vendors)
    if (isSuccess(res)) {
      yield put(BrokerDashboardActions.updateVendorDetailsAction.success(res.data && res.data.response))
    }
  } catch (e) {
    yield put(BrokerDashboardActions.updateVendorDetailsAction.failure(e))
  }
}

function* handleSelectVendorDetails(action) {
  try {
    const {
      dealId,
      agentId,
      payload,
    } = action.data
    const res = yield call(selectVendorDetailsAPI, dealId, agentId, payload)
    if (isSuccess(res)) {
      yield put(BrokerDashboardActions.selectVendorDetailsAction.success(res.data && res.data.response))
    }
  } catch (e) {
    yield put(BrokerDashboardActions.selectVendorDetailsAction.failure(e))
  }
}

function* handleGetContractStatus() {
  try {
    const res = yield call(getContractStatusAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getContractStatusAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getContractStatusAction.failure(error))
  }
}

function* handleTCSort(action) {
  try {
    console.log(action)
  } catch (error) {
    notify.show("Oop! Something went wrong", "error", 400)
  }
}

function* handleCheckout(action) {
  try {
    yield put(BrokerDashboardActions.toggleIsBusyAction.call(true))
    const agentId = yield select(state => state.brokerDashboard.agentDetailsResponse.data.id)
    const selectedPlan = yield select(state => state.brokerDashboard.selectedPlan)
    const selectedAddons = yield select(state => state.brokerDashboard.selectedAddons)
    const addOnsSelected = yield select(state => state.brokerDashboard.addOnsSelected)
    const addOnIds = addOnsSelected.map(x => x.id)
    const addOnsRemoved = yield select(state => state.brokerDashboard.addOnsRemoved)
    const addOnPlanIdsRemoved = addOnsRemoved.map(x => x.id)
    const addOnPlanIds = yield select(state => state.brokerDashboard.addOnPlanIds)
    const prevAddons = yield select(state => getAddons(state)) || []
    const couponData = yield select(state => state.brokerDashboard.couponCodeResponse.data)
    const { tokenId, currentAPISelectionMap, isCardAdded } = action.data
    // const { tokenId, currentAPISelectionMap, isCardAdded, payload } = action.data
    console.log(payload, "here saga")
    const secureId = yield getSecureIdForAgent(agentId)
    const benefitIds = selectedAddons.map(x => x.id)
    const subscriptions = yield select(state => state.agent.subscriptions.data.subscriptions)
    const subscriptionIds = subscriptions && subscriptions.map(x => x.id)
    const { currentPlan } = yield select(state => getCurrentPlan(state))
    // This will be modify call.
    if (
      !tokenId
      && subscriptionIds
      && (currentPlan && currentPlan.id === selectedPlan.planId)
    ) {
      const currentSelectionMap = createCurrentSelectedPlansMap({
        selectedAddons,
        selectedPlan,
      })
      if (currentSelectionMap && currentAPISelectionMap) {
        const { addonsIds: APIAddons } = currentAPISelectionMap || []
        const { addonsIds: selectedAddons } = currentSelectionMap || []

        const benefitIdsToRemove = APIAddons && APIAddons
          .filter(x => selectedAddons && !selectedAddons.includes(x)) || []
        const benefitIdsToAdd = selectedAddons && selectedAddons
          .filter(x => selectedAddons && APIAddons && !APIAddons.includes(x))
        const updatePayload = {
          // benefitIds: [],
          // rtl_threshold: "",
          // team_rtl_threshold: "",
          benefitIdsToAdd: addOnIds,
          benefitIdsToRemove: addOnPlanIdsRemoved,
          subscriptionIds,
          add_on_plan_ids_to_add: addOnPlanIds,
          add_on_plan_ids_to_remove: [],
        }

        console.log("update payload", updatePayload)
        yield put(BrokerDashboardActions.updateSubscriptionAction.request({
          payload: updatePayload,
          secureId,
          agentId,
        }))
        return
      }
    }

    const { id, plan_id } = addOnPlanIds[0] || {}

    const payload = {
      benefitIds: [...addOnIds],
      add_on_plan_ids: [],
      plansMap: [
        {
          planId: selectedPlan.planId,
          couponCode: get(couponData, "trialInfo.couponCode") || "",
        },
      ],
    }
    if (tokenId) {
      payload.tokenId = tokenId
    }
    if (plan_id && id) {
      payload.add_on_plan_ids = [{ plan_id, id }]
    }
    const res = yield call(checkOutBrokerPlanAPI, payload, secureId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.checkoutBrokerPlanAction.success(response))
      yield put(fetchSubscriptionsAction.request({ agentId }))
      yield put(BrokerDashboardActions.getSubsribedAddonsAction.request({ agentId }))
      yield put(BrokerDashboardActions.toggleIsBusyAction.call(false))
      yield put(BrokerDashboardActions.resetSelectionsAction.call())
    }
  } catch (error) {
    yield put(BrokerDashboardActions.checkoutBrokerPlanAction.failure(error))
    yield put(BrokerDashboardActions.toggleIsBusyAction.call(false))
  }
}

function* handleCheckoutV2(action) {
  try {
    const { payload, callback } = action.data
    const agentId = yield select(state => state.brokerDashboard.agentDetailsResponse.data.id)
    const secureId = yield getSecureIdForAgent(agentId)
    const res = yield call(checkOutBrokerPlanAPI, payload, secureId)
    if (isSuccess(res)) {
      const { response } = res.data
      Modal.success({
        content: "Agent has been subscribed to the selected plans successfully!",
      })
      yield put(BrokerDashboardActions.checkoutBrokerPlanV2Action.success(response))
      yield put(fetchSubscriptionsAction.request({ agentId }))
      yield put(BrokerDashboardActions.getSubsribedAddonsAction.request({ agentId }))
      if (callback) {
        callback()
      }
    }
  } catch (error) {
    yield put(BrokerDashboardActions.checkoutBrokerPlanV2Action.failure(error))
  }
}

function* handleUpdateSubscriptionV2(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    const agentId = yield select(state => state.brokerDashboard.agentDetailsResponse.data.id)
    const secureId = yield getSecureIdForAgent(agentId)
    const res = yield call(updateSubscriptionAPI, payload, secureId)
    if (isSuccess(res)) {
      const { response } = res.data
      Modal.success({
        content: "Agent's subcription has been updated successfully!",
      })
      yield put(BrokerDashboardActions.updateSubscriptionV2Action.success(response))
      yield put(fetchSubscriptionsAction.request({ agentId }))
      yield put(BrokerDashboardActions.getSubsribedAddonsAction.request({ agentId }))
      yield put(BrokerDashboardActions.toggleIsBusyAction.call(false))
      yield put(BrokerDashboardActions.resetSelectionsAction.call())
      if (callback) {
        callback()
      }
    }
  } catch (error) {
    yield put(BrokerDashboardActions.checkoutBrokerPlanAction.failure(error))
    yield put(BrokerDashboardActions.toggleIsBusyAction.call(false))
  }
}

function* handleUpdateSubscription(action) {
  try {
    const {
      payload,
      secureId,
      agentId,
    } = action.data
    const res = yield call(updateSubscriptionAPI, payload, secureId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.updateSubscriptionAction.success(response))
      yield put(fetchSubscriptionsAction.request({ agentId }))
      yield put(BrokerDashboardActions.getSubsribedAddonsAction.request({ agentId }))
      yield put(BrokerDashboardActions.toggleIsBusyAction.call(false))
      yield put(BrokerDashboardActions.resetSelectionsAction.call())
    }
  } catch (error) {
    yield put(BrokerDashboardActions.checkoutBrokerPlanAction.failure(error))
    yield put(BrokerDashboardActions.toggleIsBusyAction.call(false))
  }
}

function* handleCheckCouponCode(action) {
  try {
    const {
      planId,
      agentId,
      couponCode,
    } = action.data
    const payload = {
      agentId,
      couponCode,
      plans: [planId],
    }
    const res = yield call(checkCouponAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.checkCouponAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.checkCouponAction.failure(error))
  }
}

function* handleGetUsStates(action) {
  try {
    const res = yield call(getUSStatesAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getUSStatesAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getUSStatesAction.failure(error))
  }
}

function* handleSaveStates(action) {
  try {
    const {
      agentId,
      ids,
    } = action.data
    const res = yield call(saveAgentStatesAPI, agentId, { ids })
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.changeStatesForAgentAction.success(response))
      yield put(BrokerDashboardActions.getAgentStatesActions.request({ agentId }))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.changeStatesForAgentAction.failure(error))
  }
}

function* handleAgentStates(action) {
  try {
    const { agentId } = action.data || {}
    let query = ""
    if (agentId) {
      query = `?agent_id=${agentId}`
    }
    const res = yield call(getUSStatesAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getAgentStatesActions.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getAgentStatesActions.failure(error))
  }
}

function* handleAssetsDashboard(action) {
  try {
    const res = yield call(getAssetDashboardAssetsAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getAssetsDashboardAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getAssetsDashboardAction.failure(error))
  }
}

function* handleSetupProfileInfo(action) {
  try {
    const {
      agentId,
    } = action.data
    const res = yield call(getSetupProfileInfoAPI, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getSetupProfileAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getSetupProfileAction.failure(error))
  }
}

function* handleUpdateSetupProfile(action) {
  try {
    const { payload } = action.data
    const res = yield call(updateSetupProfileInfoAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.updateSetupProfileAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.updateSetupProfileAction.failure(error))
  }
}

function* handleCreateNewAssets(action) {
  try {
    const res = yield call(createNewGlobalAssetAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getAssetsDashboardAction.request())
    }
  } catch (error) {
    yield put(BrokerDashboardActions.createNewGlobalAssetAction.failure(error))
  }
}

function* handleGetAgentAssets(action) {
  try {
    const { agentId } = action.data
    const res = yield call(getAgentAssetsAPI, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getAgentAssetsActions.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getAgentAssetsActions.failure(error))
  }
}

function* handleUpdateAgentAssets(action) {
  try {
    const { agentId, payload } = action.data
    const res = yield call(updateAgentAssetAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.updateAgentAssetsActions.success(response))
      yield put(BrokerDashboardActions.getAgentAssetsActions.request({ agentId }))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.updateAgentAssetsActions.failure(error))
  }
}

function* handleUploadFiles(action) {
  try {
    const {
      files, agentId, field, form, bucketName, callback,
    } = action.data
    let bucket = ""
    if (bucketName) {
      bucket = bucketName
    } else {
      bucket = "rar-assets"
    }
    const filename = `${agentId}-${(new Date()).getTime()}`
    const signedRes = yield fetch(`https://us-central1-sturdy-chimera-767.cloudfunctions.net/file-uploader?bucket=${bucket}&filename=${filename}`)
    const signedResJson = yield signedRes.json()
    const myHeaders = new Headers({ "Content-Type": files.type })
    const uploadRes = yield fetch(signedResJson.url, {
      method: "PUT",
      headers: myHeaders,
      body: files,
    })
    yield put(BrokerDashboardActions.updateAssetsFormLinksAction.call({
      ...form,
      [field]: {
        ...form[field],
        download_url: signedResJson.fileUrlAfterUpload,
        agent_id: agentId,
        rar_asset_id: field.split("-")[1],
      },
    }))
    yield put(BrokerDashboardActions.uploadFilesAction.success(signedResJson))
    if (callback) {
      callback()
    }
  } catch (error) {
    yield put(BrokerDashboardActions.uploadFilesAction.failure(error))
  }
}

function* handleFileUploadToBucketOrientation(action) {
  try {
    const {
      file,
      objName,
      fileName,
    } = action.data

    const signedRes = yield fetch(`https://us-central1-sturdy-chimera-767.cloudfunctions.net/file-uploader?bucket=real_estate_license&filename=${file.name}`)
    const signedResJson = yield signedRes.json()
    const myHeaders = new Headers({ "Content-Type": file.type })

    const res = yield fetch(signedResJson.url, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    })

    if (res.status === 200 && signedResJson.fileUrlAfterUpload) {
      const existingObj = yield select(state => state.brokerDashboard.uploadDocsToBucketResponse.data)
      if (existingObj) {
        yield put(BrokerDashboardActions.uploadDocsToBucketAction.success({
          [objName]: {
            [objName]: signedResJson.fileUrlAfterUpload,
            [`${objName}FileName`]: fileName,
          },
          ...existingObj,
        }))
      } else {
        yield put(BrokerDashboardActions.uploadDocsToBucketAction.success({
          [objName]: {
            [objName]: signedResJson.fileUrlAfterUpload,
            [`${objName}FileName`]: fileName,
          },
        }))
      }
    }
  } catch (e) {
    console.log("error====", e)
  }
}

function* handleImageUploadForListing(action) {
  try {
    const {
      file,
      objName,
      fileName,
    } = action.data

    const signedRes = yield fetch(`https://us-central1-sturdy-chimera-767.cloudfunctions.net/file-uploader?bucket=broker_listing_images&filename=${file.name}`)
    const signedResJson = yield signedRes.json()
    const myHeaders = new Headers({ "Content-Type": file.type })

    const res = yield fetch(signedResJson.url, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    })

    if (res.status === 200 && signedResJson.fileUrlAfterUpload) {
      console.log("file upload saga", signedResJson)
      const existingObj = yield select(state => state.brokerDashboard.uploadDocsForListingResponse.data)
      if (existingObj) {
        yield put(BrokerDashboardActions.uploadDocsForListingAction.success({
          [objName]: {
            [objName]: signedResJson.fileUrlAfterUpload,
            [`${objName}FileName`]: fileName,
          },
          ...existingObj,
        }))
      } else {
        yield put(BrokerDashboardActions.uploadDocsForListingAction.success({
          [objName]: {
            [objName]: signedResJson.fileUrlAfterUpload,
            [`${objName}FileName`]: fileName,
          },
        }))
      }
    }
  } catch (e) {
    console.log("error====", e)
  }
}

function* handleFileUploadToBucketBranding(action) {
  try {
    const {
      file,
      objName,
      fileName,
      bucketName,
    } = action.data

    const signedRes = yield fetch(`https://us-central1-sturdy-chimera-767.cloudfunctions.net/file-uploader?bucket=${bucketName}&filename=${file.name}`)
    const signedResJson = yield signedRes.json()
    const myHeaders = new Headers({ "Content-Type": file.type })

    const res = yield fetch(signedResJson.url, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    })

    if (res.status === 200 && signedResJson.fileUrlAfterUpload) {
      const existingObj = yield select(state => state.brokerDashboard.uploadDocsToBucketBrandingResponse.data)
      if (existingObj) {
        yield put(BrokerDashboardActions.uploadDocsToBucketBrandingAction.success({
          [objName]: {
            [objName]: signedResJson.fileUrlAfterUpload,
            [`${objName}FileName`]: fileName,
          },
          ...existingObj,
        }))
      } else {
        yield put(BrokerDashboardActions.uploadDocsToBucketBrandingAction.success({
          [objName]: {
            [objName]: signedResJson.fileUrlAfterUpload,
            [`${objName}FileName`]: fileName,
          },
        }))
      }
    }
  } catch (e) {
    console.log("error====", e)
  }
}

function* handleUploadSellerProfileImage(action) {
  try {
    const {
      file,
      objName,
      fileName,
      bucketName,
      callback,
    } = action.data

    const signedRes = yield fetch(`https://us-central1-sturdy-chimera-767.cloudfunctions.net/file-uploader?bucket=${bucketName}&filename=${fileName}`)
    const signedResJson = yield signedRes.json()
    const myHeaders = new Headers({ "Content-Type": file.type })

    const res = yield fetch(signedResJson.url, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    })
    if (res.status === 200 && signedResJson.fileUrlAfterUpload) {
      yield put(BrokerDashboardActions.uploadSellerProfileImageAction.success(signedResJson.fileUrlAfterUpload))
      const s3url = signedResJson.fileUrlAfterUpload
      const s3file = s3url.split("/")
      const s3filename = s3file[s3file.length - 1]
      const url = `https://s3.amazonaws.com/files.radiusagent.com/scheduled_event_rooms/${s3filename}`
      if (callback) callback(url);
    }

  } catch (e) {
    console.log(e)
  }
}

function* handleRosterPage(action) {
  try {
    const {
      limit = 200,
      skip,
      plan,
      agentEmail,
      isFetchingMore,
      productRequest,
      sortBy,
      fromDate,
      toDate,
      minDealsPerMonth,
      maxDealsPerMonth,
      minDeals,
      maxDeals,
      teamId,
      state,
      churnedFilter,
      assistPlan,
      dealsDateFrom,
      dealsDateTo,
      tc_id,
      fromChurnedDate,
      toChurnedDate,
      isLicenseTransferred,
      isIcaSigned,
      pastBrokerageName,
      productionTier,
      primarySaleRepresentative,
      secondarySaleRepresentative,
      agentType,
      minCap,
      maxCap,
      minPastBrokerageProduction,
      maxPastBrokerageProduction,
      city,
    } = action.data
    let query = "?"
    if (limit) {
      query += `limit=${limit}`
    }
    if (skip) {
      query += `&skip=${skip}`
    }
    if (plan) {
      query += `&plan=${plan}`
    }
    if (agentEmail) {
      query += `&agentEmail=${agentEmail}`
    }
    if (productRequest) {
      query += `&productRequest=${productRequest}`
    }
    if (sortBy) {
      query += `&${sortBy}`
    }
    if (fromDate) {
      query += `&fromDate=${fromDate}`
    }
    if (tc_id) {
      query += `&tc_id=${tc_id}`
    }
    if (toDate) {
      query += `&toDate=${toDate}`
    }
    if (minDealsPerMonth) {
      query += `&minDealsPerMonth=${minDealsPerMonth}`
    }
    if (maxDealsPerMonth) {
      query += `&maxDealsPerMonth=${maxDealsPerMonth}`
    }
    if (minDeals) {
      query += `&minDeals=${minDeals}`
    }
    if (maxDeals) {
      query += `&maxDeals=${maxDeals}`
    }
    if (teamId) {
      query += `&teamId=${teamId}`
    }
    if (state) {
      query += `&state=${state}`
    }
    if (churnedFilter) {
      query += `&churnedFilter=${churnedFilter}`
    }
    if (assistPlan) {
      query += `&assistPlan=${assistPlan}`
    }
    if (dealsDateFrom) {
      query += `&dealsDateFrom=${dealsDateFrom}`
    }
    if (dealsDateTo) {
      query += `&dealsDateTo=${dealsDateTo}`
    }
    if (fromChurnedDate) {
      query += `&fromChurnedDate=${fromChurnedDate}`
    }
    if (toChurnedDate) {
      query += `&toChurnedDate=${toChurnedDate}`
    }
    if (isLicenseTransferred === 0 || isLicenseTransferred) {
      query += `&isLicenseTransferred=${isLicenseTransferred}`
    }
    if (isIcaSigned === 0 || isIcaSigned) {
      query += `&isIcaSigned=${isIcaSigned}`
    }
    if (pastBrokerageName) {
      query += `&pastBrokerageName=${pastBrokerageName}`
    }
    if (productionTier) {
      query += `&productionTier=${productionTier}`
    }
    if (primarySaleRepresentative) {
      query += `&primarySaleRepresentative=${primarySaleRepresentative}`
    }
    if (secondarySaleRepresentative) {
      query += `&secondarySaleRepresentative=${secondarySaleRepresentative}`
    }
    if (agentType) {
      query += `&agentType=${agentType}`
    }
    if (minCap) {
      query += `&minCap=${minCap}`
    }
    if (maxCap) {
      query += `&maxCap=${maxCap}`
    }
    if (minPastBrokerageProduction) {
      query += `&minPastBrokerageProduction=${minPastBrokerageProduction}`
    }
    if (maxPastBrokerageProduction) {
      query += `&maxPastBrokerageProduction=${maxPastBrokerageProduction}`
    }
    if (city) {
      query += `&city=${city}`
    }

    const res = yield call(getBrokerAgentAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      let list = {}
      if (isFetchingMore) {
        const result = yield select(state => state.brokerDashboard.rosterPageAgentsResponse.data.result)
        list.result = [
          ...result,
          ...response.result,
        ]
        list.overview = response.overview
        if (response.result.length === 0) {
          list.isDone = true
        }
      } else {
        list = response
      }
      yield put(BrokerDashboardActions.getAgentForRosterPageAction.success(list))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getAgentForRosterPageAction.failure(error))
  }
}

function* handleGetAgentYearEndWrap(action) {
  try {
    const {
      agentId,
    } = action.data

    let query = "?"

    if (agentId) {
      query += `agentId=${agentId}`
    }

    const res = yield call(getAgentYearWrapAPI, query)

    if (isSuccess(res)) {
      const { response } = res.data

      yield put(BrokerDashboardActions.getAgentYearEndWrapAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getAgentYearEndWrapAction.failure(error))
  }
}

function* handleRosterPageDataDownload(action) {
  try {
    const {
      limit = 200,
      skip,
      plan,
      agentEmail,
      isFetchingMore,
      productRequest,
      sortBy,
      fromDate,
      toDate,
      minDealsPerMonth,
      maxDealsPerMonth,
      minDeals,
      maxDeals,
      teamId,
      state,
      churnedFilter,
      assistPlan,
      dealsDateFrom,
      dealsDateTo,
      tc_id,
      fromChurnedDate,
      toChurnedDate,
      isLicenseTransferred,
      isIcaSigned,
      pastBrokerageName,
      productionTier,
      primarySaleRepresentative,
      secondarySaleRepresentative,
      agentType,
      minCap,
      maxCap,
      minPastBrokerageProduction,
      maxPastBrokerageProduction,
    } = action.data
    let query = "?"
    if (limit) {
      query += `limit=${limit}`
    }
    if (skip) {
      query += `&skip=${skip}`
    }
    if (plan) {
      query += `&plan=${plan}`
    }
    if (agentEmail) {
      query += `&agentEmail=${agentEmail}`
    }
    if (state) {
      query += `&state=${state}`
    }
    if (productRequest) {
      query += `&productRequest=${productRequest}`
    }
    if (tc_id) {
      query += `&tc_id=${tc_id}`
    }
    if (sortBy) {
      query += `&${sortBy}`
    }
    if (fromDate) {
      query += `&fromDate=${fromDate}`
    }
    if (toDate) {
      query += `&toDate=${toDate}`
    }
    if (minDealsPerMonth) {
      query += `&minDealsPerMonth=${minDealsPerMonth}`
    }
    if (maxDealsPerMonth) {
      query += `&maxDealsPerMonth=${maxDealsPerMonth}`
    }
    if (minDeals) {
      query += `&minDeals=${minDeals}`
    }
    if (maxDeals) {
      query += `&maxDeals=${maxDeals}`
    }
    if (teamId) {
      query += `&teamId=${teamId}`
    }
    if (churnedFilter) {
      query += `&churnedFilter=${churnedFilter}`
    }
    if (fromChurnedDate) {
      query += `&fromChurnedDate=${fromChurnedDate}`
    }
    if (toChurnedDate) {
      query += `&toChurnedDate=${toChurnedDate}`
    }
    if (assistPlan) {
      query += `&assistPlan=${assistPlan}`
    }
    if (dealsDateFrom) {
      query += `&dealsDateFrom=${dealsDateFrom}`
    }
    if (dealsDateTo) {
      query += `&dealsDateTo=${dealsDateTo}`
    }
    if (isLicenseTransferred === 0 || isLicenseTransferred) {
      query += `&isLicenseTransferred=${isLicenseTransferred}`
    }
    if (isIcaSigned === 0 || isIcaSigned) {
      query += `&isIcaSigned=${isIcaSigned}`
    }
    if (pastBrokerageName) {
      query += `&pastBrokerageName=${pastBrokerageName}`
    }
    if (productionTier) {
      query += `&productionTier=${productionTier}`
    }
    if (primarySaleRepresentative) {
      query += `&primarySaleRepresentative=${primarySaleRepresentative}`
    }
    if (secondarySaleRepresentative) {
      query += `&secondarySaleRepresentative=${secondarySaleRepresentative}`
    }
    if (agentType) {
      query += `&agentType=${agentType}`
    }
    if (minCap) {
      query += `&minCap=${minCap}`
    }
    if (maxCap) {
      query += `&maxCap=${maxCap}`
    }
    if (minPastBrokerageProduction) {
      query += `&minPastBrokerageProduction=${minPastBrokerageProduction}`
    }
    if (maxPastBrokerageProduction) {
      query += `&maxPastBrokerageProduction=${maxPastBrokerageProduction}`
    }
    query += `&downloadRequest=${true}`

    const res = yield call(brokerDataDownloadAPI, query)
    const { data } = res
    const blob = new Blob([data], { type: "text/csv;charset=utf-8;" })
    const link = document.createElement("a")
    link.setAttribute("href", URL.createObjectURL(blob))
    const currentDate = new Date()
    link.setAttribute("download", `${currentDate.getTime()}_${format(currentDate, "MMM_dd_yyyy")}.csv`)
    document.body.appendChild(link)
    link.click()
    yield put(BrokerDashboardActions.rosterPageDataDownloadAction.success(data))
  } catch (error) {
    yield put(BrokerDashboardActions.rosterPageDataDownloadAction.failure(error))
  }
}

function* handleAgentPipelinePage(action) {
  try {
    const {
      limit,
      skip,
      source,
      agentEmail,
      isFetchingMore,
      sortBy,
      fromClaimed,
      toClaimed,
      agentType,
      sortData,
    } = action.data
    let query = "?"
    if (limit) {
      query += `limit=${limit}`
    }
    if (skip) {
      query += `&skip=${skip}`
    }
    if (source) {
      query += `&source=${source}`
    }
    if (agentEmail) {
      query += `&agentEmail=${agentEmail}`
    }
    if (sortBy) {
      query += `&${sortBy}`
    }
    if (fromClaimed) {
      query += `&fromClaimed=${fromClaimed}`
    }
    if (toClaimed) {
      query += `&toClaimed=${toClaimed}`
    }
    if (agentType) {
      query += `&agentType=${agentType}`
    }
    if (sortData) {
      if (sortData.createdAtSortOrder) {
        query += `&createdAtSortOrder=${sortData.createdAtSortOrder}`
      }
    }

    const res = yield call(getAgentPipelineAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      const list = {}
      if (isFetchingMore) {
        const result = yield select(state => state.brokerDashboard.agentPipelinePageResponse.data.result)
        list.result = [
          ...result,
          ...response,
        ]
        if (response.length === 0) {
          list.isDone = true
        }
      } else {
        list.result = response
      }
      list.agent_type = agentType
      yield put(BrokerDashboardActions.getAgentPipelinePageAction.success(list))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getAgentPipelinePageAction.failure(error))
  }
}

function* handleTCPageOverview(action) {
  try {
    const {
      agentId,
      clientType,
      teamId,
      searchId,
      sourceType,
      planCategory,
      agent_state,
      property_state,
      city,
      tc_id,
      listingAddress,
      transaction_type,
      radius_transaction_type,
      fromAcceptance,
      toAcceptance,
      fromLicenseTransferredDate,
      toLicenseTransferredDate,
      dealType,
      viewContract,
      fromSubmission,
      toSubmission,
      fromListing,
      toListing,
      sortData,
      startDate,
      endDate,
      primarySaleRepresentative,
      secondarySaleRepresentative,
      agentType,
      isShowActiveListing,
      fromDealCreated,
      toDealCreated,
    } = action.data
    let query = "?"
    if (agentId) {
      query += `&agentId=${agentId}`
    }
    if (clientType) {
      query += `&clientType=${clientType}`
    }
    if (teamId) {
      query += `&teamId=${teamId}`
    }
    if (searchId) {
      query += `&agentId=${searchId}`
    }
    if (sourceType) {
      query += `&sourceType=${sourceType}`
    }
    if (planCategory) {
      query += `&plan=${planCategory}`
    }
    if (agent_state) {
      query += `&agent_state=${agent_state}`
    }
    if (property_state) {
      query += `&property_state=${property_state}`
    }
    if (tc_id) {
      query += `&tc_id=${tc_id}`
    }
    if (listingAddress) {
      query += `&listingAddress=${listingAddress}`
    }
    if (transaction_type) {
      query += `&transaction_type=${transaction_type}`
    }
    if (radius_transaction_type) {
      query += `&radius_transaction_type=${radius_transaction_type}`
    }
    if (fromAcceptance) {
      query += `&fromAcceptance=${fromAcceptance}`
    }
    if (toAcceptance) {
      query += `&toAcceptance=${toAcceptance}`
    }
    if (fromLicenseTransferredDate) {
      query += `&fromLicenseTransferredDate=${fromLicenseTransferredDate}`
    }
    if (toLicenseTransferredDate) {
      query += `&toLicenseTransferredDate=${toLicenseTransferredDate}`
    }
    if (dealType) {
      query += `&dealType=${dealType}`
    }
    if (viewContract || viewContract === 0) {
      query += `&viewContract=${viewContract}`
    }
    if (fromSubmission) {
      query += `&fromSubmission=${fromSubmission}`
    }
    if (toSubmission) {
      query += `&toSubmission=${toSubmission}`
    }
    if (fromDealCreated) {
      query += `&fromDealCreated=${fromDealCreated}`
    }
    if (toDealCreated) {
      query += `&toDealCreated=${toDealCreated}`
    }
    if (fromListing) {
      query += `&fromListing=${fromListing}`
    }
    if (toListing) {
      query += `&toListing=${toListing}`
    }
    if (primarySaleRepresentative) {
      query += `&primarySaleRepresentative=${primarySaleRepresentative}`
    }
    if (secondarySaleRepresentative) {
      query += `&secondarySaleRepresentative=${secondarySaleRepresentative}`
    }
    if (agentType) {
      query += `&agentType=${agentType}`
    }
    if (isShowActiveListing) {
      query += "&fileType=new_listing"
    }
    if (sortData) {
      if (sortData.createdAtSortOrder) {
        query += `&createdAtSortOrder=${sortData.createdAtSortOrder}`
      }
    }
    if (startDate) {
      query += `&fromClosed=${startDate}`
    }
    if (endDate) {
      query += `&toClosed=${endDate}`
    }
    if (city) {
      query += `&city=${city}`
    }

    const res = yield call(tcOverviewAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.tcOverviewAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.tcOverviewAction.failure(error))
  }
}

function* handleTCPageListingOverview(action) {
  try {
    const {
      agentId,
      clientType,
      teamId,
      searchId,
      sourceType,
      planCategory,
      agent_state,
      property_state,
      tc_id,
      listingAddress,
      transaction_type,
      radius_transaction_type,
      fromAcceptance,
      toAcceptance,
      fromLicenseTransferredDate,
      toLicenseTransferredDate,
      dealType,
      viewContract,
      fromSubmission,
      toSubmission,
      fromListing,
      toListing,
      sortData,
      startDate,
      endDate,
      primarySaleRepresentative,
      secondarySaleRepresentative,
      agentType,
      isShowActiveListing,
    } = action.data
    let query = "?"
    if (agentId) {
      query += `&agentId=${agentId}`
    }
    if (clientType) {
      query += `&clientType=${clientType}`
    }
    if (teamId) {
      query += `&teamId=${teamId}`
    }
    if (searchId) {
      query += `&agentId=${searchId}`
    }
    if (sourceType) {
      query += `&sourceType=${sourceType}`
    }
    if (planCategory) {
      query += `&plan=${planCategory}`
    }
    if (agent_state) {
      query += `&agent_state=${agent_state}`
    }
    if (property_state) {
      query += `&property_state=${property_state}`
    }
    if (tc_id) {
      query += `&tc_id=${tc_id}`
    }
    if (listingAddress) {
      query += `&listingAddress=${listingAddress}`
    }
    if (transaction_type) {
      query += `&transaction_type=${transaction_type}`
    }
    if (radius_transaction_type) {
      query += `&radius_transaction_type=${radius_transaction_type}`
    }
    if (fromAcceptance) {
      query += `&fromAcceptance=${fromAcceptance}`
    }
    if (toAcceptance) {
      query += `&toAcceptance=${toAcceptance}`
    }
    if (fromLicenseTransferredDate) {
      query += `&fromLicenseTransferredDate=${fromLicenseTransferredDate}`
    }
    if (toLicenseTransferredDate) {
      query += `&toLicenseTransferredDate=${toLicenseTransferredDate}`
    }
    if (dealType) {
      query += `&dealType=${dealType}`
    }
    if (viewContract || viewContract === 0) {
      query += `&viewContract=${viewContract}`
    }
    if (fromSubmission) {
      query += `&fromSubmission=${fromSubmission}`
    }
    if (toSubmission) {
      query += `&toSubmission=${toSubmission}`
    }
    if (fromListing) {
      query += `&fromListing=${fromListing}`
    }
    if (toListing) {
      query += `&toListing=${toListing}`
    }
    if (primarySaleRepresentative) {
      query += `&primarySaleRepresentative=${primarySaleRepresentative}`
    }
    if (secondarySaleRepresentative) {
      query += `&secondarySaleRepresentative=${secondarySaleRepresentative}`
    }
    if (agentType) {
      query += `&agentType=${agentType}`
    }
    if (isShowActiveListing) {
      query += "&fileType=new_listing"
    }
    if (sortData) {
      if (sortData.createdAtSortOrder) {
        query += `&createdAtSortOrder=${sortData.createdAtSortOrder}`
      }
    }
    if (startDate) {
      query += `&fromClosed=${startDate}`
    }
    if (endDate) {
      query += `&toClosed=${endDate}`
    }

    const res = yield call(tcOverviewListingAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.tcOverviewListingAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.tcOverviewListingAction.failure(error))
  }
}

function* handleReferralSources(action) {
  try {
    const res = yield call(getReferralSourcesAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getReferralSourcesAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getReferralSourcesAction.failure(error))
  }
}

function* handleDownloadTcData(action) {
  try {
    const {
      agentId,
      clientType,
      teamId,
      searchId,
      sourceType,
      planCategory,
      agent_state,
      property_state,
      tc_id,
      listingAddress,
      transaction_type,
      radius_transaction_type,
      fromAcceptance,
      toAcceptance,
      fromLicenseTransferredDate,
      toLicenseTransferredDate,
      dealType,
      viewContract,
      fromSubmission,
      toSubmission,
      fromListing,
      toListing,
      sortData,
      startDate,
      endDate,
      isShowActiveListing,
      fromDealCreated,
      toDealCreated,
    } = action.data
    let query = "?"
    if (agentId) {
      query += `&agentId=${agentId}`
    }
    if (clientType) {
      query += `&clientType=${clientType}`
    }
    if (teamId) {
      query += `&teamId=${teamId}`
    }
    if (searchId) {
      query += `&agentId=${searchId}`
    }
    if (sourceType) {
      query += `&sourceType=${sourceType}`
    }
    if (planCategory) {
      query += `&plan=${planCategory}`
    }
    if (agent_state) {
      query += `&agent_state=${agent_state}`
    }
    if (property_state) {
      query += `&property_state=${property_state}`
    }
    if (tc_id) {
      query += `&tc_id=${tc_id}`
    }
    if (listingAddress) {
      query += `&listingAddress=${listingAddress}`
    }
    if (transaction_type) {
      query += `&transaction_type=${transaction_type}`
    }
    if (radius_transaction_type) {
      query += `&radius_transaction_type=${radius_transaction_type}`
    }
    if (fromAcceptance) {
      query += `&fromAcceptance=${fromAcceptance}`
    }
    if (toAcceptance) {
      query += `&toAcceptance=${toAcceptance}`
    }
    if (fromLicenseTransferredDate) {
      query += `&fromLicenseTransferredDate=${fromLicenseTransferredDate}`
    }
    if (toLicenseTransferredDate) {
      query += `&toLicenseTransferredDate=${toLicenseTransferredDate}`
    }
    if (dealType) {
      query += `&dealType=${dealType}`
    }
    if (viewContract || viewContract === 0) {
      query += `&viewContract=${viewContract}`
    }
    if (fromSubmission) {
      query += `&fromSubmission=${fromSubmission}`
    }
    if (toSubmission) {
      query += `&toSubmission=${toSubmission}`
    }
    if (fromListing) {
      query += `&fromListing=${fromListing}`
    }
    if (fromDealCreated) {
      query += `&fromDealCreated=${fromDealCreated}`
    }
    if (toDealCreated) {
      query += `&toDealCreated=${toDealCreated}`
    }
    if (toListing) {
      query += `&toListing=${toListing}`
    }
    if (isShowActiveListing) {
      query += "&fileType=new_listing"
    }
    if (sortData) {
      if (sortData.createdAtSortOrder) {
        query += `&createdAtSortOrder=${sortData.createdAtSortOrder}`
      }
    }
    if (startDate) {
      query += `&fromClosed=${startDate}`
    }
    if (endDate) {
      query += `&toClosed=${endDate}`
    }
    const res = yield call(downloadTcDataAPI, query)
    const { data } = res
    const blob = new Blob([data], { type: "text/csv;charset=utf-8;" })
    const link = document.createElement("a")
    link.setAttribute("href", URL.createObjectURL(blob))
    const currentDate = new Date()
    link.setAttribute("download", `${currentDate.getTime()}_${format(currentDate, "MMM_dd_yyyy")}.csv`)
    document.body.appendChild(link)
    link.click()
    yield put(BrokerDashboardActions.tcDataDownloadAction.success(data))
  } catch (error) {
    yield put(BrokerDashboardActions.tcDataDownloadAction.failure(error))
  }
}

function* cancelSubscription(action) {
  try {
    const { agentId, callback } = action.data
    yield put(BrokerDashboardActions.toggleIsBusyAction.call(true))
    const { currentPlan } = yield select(state => getCurrentPlan(state))
    const planId = get(currentPlan, "id")
    if (planId) {
      yield put(BrokerDashboardActions.resetSelectionsAction.call())
      const secureId = yield getSecureIdForAgent(agentId)
      const payload = {
        planId,
        now: true,
      }
      const res = yield call(cancelPlanAPI, payload, secureId)
      if (isSuccess(res)) {
        yield put(fetchSubscriptionsAction.request({ agentId }))
        yield put(BrokerDashboardActions.resetSelectionsAction.call())
        yield put(BrokerDashboardActions.toggleIsBusyAction.call(false))
        yield put(BrokerDashboardActions.getAgentCardDetailsAction.request({ agentId }))
        if (callback) {
          callback()
        }
      }
    }
  } catch (error) {
    yield put(BrokerDashboardActions.cancelSubscriptionAction.failure(error))
    yield put(BrokerDashboardActions.toggleIsBusyAction.call(false))
  }
}

function* handleGlobalAssetDelete(action) {
  try {
    const { assetId } = action.data
    const res = yield call(deleteGlobalAssetAPI, assetId)
    if (isSuccess(res)) {
      const { response } = res.data
      if (response.code === 500 && response.errorCode === 100) {
        notify.show(response.errorMessage, "error")
        return
      }
      notify.show("Asset Removed", "success")
      yield put(BrokerDashboardActions.getAssetsDashboardAction.request())
    }
  } catch (error) {
    yield put(BrokerDashboardActions.deleteGlobalAssetAction.failure(error))
  }
}

function* handleClientSearch(action) {
  try {
    const { text, agentId, secureId } = action.data
    console.log({ text, agentId, secureId })
    const payload = {
      text,
      sort_by: "created_at",
      ascending: false,
      aggregationNotRequired: false,
      secure_id: secureId,
      start: 0,
      end: 10,
      agentId: Number(agentId),
    }
    console.log({ payload })
    const res = yield call(searchClientAPI, payload, secureId)
    if (isSuccess(res)) {
      const { response } = res.data
      console.log({ response })
    }
  } catch (error) {
    console.warn(error)
  }
}

function* handleGetAgentSecureId(action) {
  try {
    const { agentId } = action.data
    const res = yield call(getSecureIdAPI, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getSecureIdForTCAction.success({ secureId: response.secure_id }))
      console.log({ response })
    }
  } catch (error) {

  }
}

function* handleGetDocusignTemplate() {
  try {
    const res = yield call(getDocusignTemplateAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getDocusignTemplateAction.success(response))
      console.log("template response saga", response)
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getDocusignTemplateAction.success(error))
  }
}

function* handleTCFiles(action) {
  try {
    const { tcId } = action.data
    yield put(BrokerDashboardActions.getFileForTCAction.clear())
    const res = yield call(getTCFileAPI, tcId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getFileForTCAction.success(response))
      console.log({ response })
    }
  } catch (error) {

  }
}

function* handleSaveSteps(action) {
  try {
    const { payload, callback } = action.data
    const user = cookie.load("user")
    payload.admin_name = user.twilio_client_name || "anonymous"
    const res = yield call(saveStepsAPI, payload)
    if (isSuccess(res)) {
      notify.show("Deal Saved", "success")
      yield put(BrokerDashboardActions.saveStepsForProductAction.success(res))
      callback()
    }
  } catch (err) {
    // Try to handle all Error messages from backend. This was special case where the backend isn't handeling 4xx errors, hence this way of implementation.
    if (err.status === 401) {
      notification.error({
        message: "Missing Details!",
        description: err.data.error.message,
        duration: 5,
        placement: "top",
      })
      yield put(BrokerDashboardActions.showTCLoaderActions.call(false))
    }
    yield put(BrokerDashboardActions.saveStepsForProductAction.failure(err))
  }
}

function* handleSaveStepsListings(action) {
  try {
    const { payload, callback } = action.data
    const user = cookie.load("user")
    payload.admin_name = user.twilio_client_name || "anonymous"
    const res = yield call(saveListingStepsAPI, payload)
    if (isSuccess(res)) {
      notify.show("Listing Status Saved", "success")
      yield put(BrokerDashboardActions.saveListingStepsAction.success(res.data))
      callback()
    }
  } catch (err) {
    // Try to handle all Error messages from backend. This was special case where the backend isn't handeling 4xx errors, hence this way of implementation.
    if (err.status === 401) {
      notification.error({
        message: "Missing Details!",
        description: err.data.error.message,
        duration: 5,
        placement: "top",
      })
      yield put(BrokerDashboardActions.showTCLoaderActions.call(false))
    }
    yield put(BrokerDashboardActions.saveListingStepsAction.failure(err))
  }
}

function* handleUpdateUserSettings(action) {
  try {
    const {
      payload, agentId, pageType = null, isPageRefresh,
    } = action.data
    const res = yield call(userUserSettingsAPI, agentId, payload)
    if (pageType) {
      yield put(BrokerDashboardActions.updateStarredStatusInStateAction.call({
        ...action.data,
      }))
    }
    if (isSuccess(res)) {
      const { response } = res.data
      notify.hide()
      notify.show("Update successful", "success")
      if (pageType) {
        yield put(BrokerDashboardActions.updateUserSettingsAction.success(response))
      } else {
        yield put(fetchUserReferralSettingsAction.request({ agentId }))
      }
      if (isPageRefresh) {
        window.location.reload()
      }
    }
  } catch (error) {
    const { pageType = null } = action.data
    if (pageType) {
      yield put(BrokerDashboardActions.updateUserSettingsAction.failure(error))
      notify.show("Something went wrong", "error")
      console.log(error)
    } else {
      yield put(fetchUserReferralSettingsAction.failure(error))
      console.log(error)
    }
  }
}

function* handleStarredStatus(action) {
  try {
    const { agentId, payload, pageType } = action.data
    if (pageType === "roster") {
      const fetchedResults = yield select(state => state.brokerDashboard.rosterPageAgentsResponse.data)
      const updatedAgent = fetchedResults.result.find(x => x.id === agentId)
      const updatedAgentIndex = fetchedResults.result.findIndex(x => x.id === agentId)
      if (updatedAgent) {
        const mainResults = yield select(state => state.brokerDashboard.rosterPageAgentsResponse.data)
        mainResults.result[updatedAgentIndex] = {
          ...mainResults.result[updatedAgentIndex],
          starred_for_lmp: payload.starred_for_lmp,
        }
        const mainResultResponse = {
          ...mainResults,
          result: [
            ...mainResults.result,
          ],
        }
        yield put(BrokerDashboardActions.getAgentForRosterPageAction.success(mainResultResponse))
      }
    } else if (pageType === "agent-pipeline") {
      const fetchedResults = yield select(state => state.brokerDashboard.agentPipelinePageResponse.data)
      const updatedAgent = fetchedResults.result.find(x => x.agent_id === agentId)
      const updatedAgentIndex = fetchedResults.result.findIndex(x => x.agent_id === agentId)
      if (updatedAgent) {
        const mainResults = yield select(state => state.brokerDashboard.agentPipelinePageResponse.data)
        mainResults.result[updatedAgentIndex] = {
          ...mainResults.result[updatedAgentIndex],
          starred_for_lmp: payload.starred_for_lmp,
        }
        const mainResultResponse = {
          ...mainResults,
          result: [
            ...mainResults.result,
          ],
        }
        yield put(BrokerDashboardActions.getAgentPipelinePageAction.success(mainResultResponse))
      }
    }
  } catch (error) {
    notify.show("Something went wrong", "error")
  }
}

function* handleFetchCitiesServed(action) {
  try {
    const { username } = action.data
    const res = yield call(getCurrentCitiesServedAPI, username)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getCurrentCitiesServedAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getCurrentCitiesServedAction.failure(error))
  }
}

function* handleFetchNeighbourhoodsFromCity(action) {
  try {
    const { city } = action.data
    const res = yield call(getNeighbourHoodFromCitiesAPI, city)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getNeighbourHoodFromCitiesAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getNeighbourHoodFromCitiesAction.failure(error))
  }
}

function* handleSaveNeighbourhoodForAgent(action) {
  try {
    const { payload } = action.data
    const res = yield call(saveNeighbourhoodsAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.saveAgentServedNeighbourhoodAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.saveAgentServedNeighbourhoodAction.failure(error))
  }
}

function* handleDeleteNeighbourhood(action) {
  try {
    const { payload } = action.data
    const res = yield call(deleteNeighbourhoodAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.deleteNeighbourhoodAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.deleteNeighbourhoodAction.failure(error))
  }
}

function* handleGetAllResources(action) {
  try {
    const {
      isQuery, resourceId, selectedStates, isFilter,
    } = action.data
    let query = ""
    if (isQuery) {
      query = "?"
      if (resourceId) {
        query = `${query}id=${resourceId}`
      }
      if (selectedStates) {
        const selctedStatesQ = selectedStates.map(x => x.value).join(",").trim()
        query = `${query}state=${lowerCase(selctedStatesQ)}`
      }
    }
    const res = yield call(getAllResourcesAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      if (isQuery && !isFilter) {
        const singleResource = get(response, "response[0]")
        yield put(BrokerDashboardActions.getResourcesListAction.success({
          editResource: singleResource,
          isEdit: true,
        }))
        return
      }
      yield put(BrokerDashboardActions.getResourcesListAction.success(response.response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getResourcesListAction.failure(error))
  }
}

function* handleTCList() {
  try {
    LogRocket.initialize()
    const res = yield call(getTCListAPI)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      // const finalRes = response.data.map(x => ({ label: `${x.tc_firstname} ${x.tc_lastname}`, value: x.id }))
      yield put(BrokerDashboardActions.getTCListAction.success(response))
      // return finalRes
    }
  } catch (e) {
    // createToast.error("Something went wrong..", toastConfig)
    yield put(BrokerDashboardActions.getTCListAction.failure(e))
  }
}

//status
function* handleDealStatusTimeline(action) {
  try {
    //here
    const {
      listingId,
    } = action.data
    //here
    const res = yield call(getDealStatusTimelineAPI, listingId)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(BrokerDashboardActions.getDealStatusTimelineAction.success(response))
    }
  } catch (e) {
    yield put(BrokerDashboardActions.getDealStatusTimelineAction.failure(e))
  }
}

function* handleCreateResource(action) {
  try {
    const { payload, history } = action.data
    const res = yield call(createResourceAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.createResourceAction.success(response))
      history.push("/broker/resources")
    }
  } catch (error) {
    yield put(BrokerDashboardActions.createResourceAction.failure(error))
  }
}

function* handleResoucrUpdate(action) {
  try {
    const { payload, history } = action.data
    const res = yield call(updateResourceAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.updateResourcesAction.success(response))
      history.push("/broker/resources")
    }
  } catch (error) {
    yield put(BrokerDashboardActions.updateResourcesAction.failure(error))
    notify.show("Server Error", "error", 3000)
  }
}

function* handleUpdateTcAgent(action) {
  try {
    const { dealId, tcId } = action.data
    const payload = {
      deal_id: dealId,
      tc_id: tcId,
    }
    const res = yield call(updateTCtoDealAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.updateTCAgentAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.updateResourcesAction.failure(error))
    notify.show("Server Error", "error", 3000)
  }
}

function* handleUpdateAgentAssignedTC(action) {
  try {
    const { agentId, tcId } = action.data
    const payload = {
      agent_id: agentId,
      tc_id: tcId,
    }
    console.log("payload", payload)
    const res = yield call(updateAgentOptedTCAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.updateAgentOptedTCAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.updateAgentOptedTCAction.failure(error))
    notify.show("Server Error", "error", 3000)
  }
}

function* handleFetchTCAgentAssigned(action) {
  try {
    const { agentId } = action.data
    const res = yield call(getTCAgentAssignedAPI, agentId)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(BrokerDashboardActions.getTCAgentAssignedAction.success(response))
    }
  } catch (e) {
    // createToast.error("Something went wrong..", toastConfig)
    yield put(BrokerDashboardActions.getTCAgentAssignedAction.failure(e))
  }
}

function* handleDeleteResource(action) {
  try {
    const { payload, history } = action.data
    const res = yield call(deleteResourceAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.deleteResourcesAction.success(response))
      window.location.reload()
    }
  } catch (error) {
    yield put(BrokerDashboardActions.deleteResourcesAction.failure(error))
  }
}

function* handleCommissionDemandUpdate(action) {
  try {
    const { payload, callback } = action.data
    const res = yield call(updateCommissionDemandAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      if (callback instanceof Function) callback()
      const dealList = yield select(state => state.brokerDashboard.dealsListingResponse.data)
      const itemIndex = dealList.findIndex(x => x.id === payload.deal_id)
      dealList[itemIndex].commission_demand_url = payload.url
      yield put(BrokerDashboardActions.updateCommsionDemandAction.success(response))
      yield put(BrokerDashboardActions.getAllDealsListingActions.success(dealList))
      notify.show("Commission Demand Added", "success")
    }
  } catch (error) {
    console.log(error)
    yield put(BrokerDashboardActions.updateCommsionDemandAction.failure(error))
  }
}

function* hableDeleteCommissionDemand(action) {
  try {
    console.log("aadasd")
    const { deal_id } = action.data
    const res = yield call(deleteCommissionDemandAPI, deal_id)
    if (isSuccess(res)) {
      const dealList = yield select(state => state.brokerDashboard.dealsListingResponse.data)
      const itemIndex = dealList.findIndex(x => x.id === deal_id)
      dealList[itemIndex].commission_demand_url = null
      yield put(BrokerDashboardActions.getAllDealsListingActions.success(dealList))
      notify.show("Commission Demand Removed", "success")
    }
  } catch (error) {
    notify.show("Something went wrong!", "error")
  }
}

function* handleSlackDealStatusChange(action) {
  try {
    const { agentId, dealId, dealStatus } = action.data
    let query = "?"
    if (agentId) {
      query += `&agentId=${agentId}`
    }
    if (dealId) {
      query += `&dealId=${dealId}`
    }
    if (dealStatus) {
      query += `&dealStatus=${dealStatus}`
    }
    const res = yield call(slackNotifyDealStatusChangeAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.sendSlackDealStatusChangeAction.success(response))
    }
  } catch (e) {
    yield put(BrokerDashboardActions.sendSlackDealStatusChangeAction.failure(e))
  }
}

function* handleGetListingCoverSheet(action) {
  try {
    const dealId = action?.data?.dealId
    const callAPI = action?.data?.callAPI
    const dealAgentId = action?.data?.dealAgentId

    let res1 = { data: { response: {} } }
    let res2 = { data: { response: {} } }
    let res3 = { data: { response: {} } }
    let res4 = { data: { response: {} } }
    let res5 = { data: { response: {} } }
    let res6 = { data: { response: {} } }
    let res7 = { data: { response: {} } }
    let res8 = { data: { response: {} } }

    if (callAPI.includes("agent_info")) res1 = yield call(getListingCoverSheetAPI, `${dealId}/agent_info?agent_id=${dealAgentId}`)
    if (callAPI.includes("seller_info")) res2 = yield call(getListingCoverSheetAPI, `${dealId}/seller_info?agent_id=${dealAgentId}`)
    if (callAPI.includes("source_time")) res3 = yield call(getListingCoverSheetAPI, `${dealId}/source_time?agent_id=${dealAgentId}`)
    //if (callAPI.includes("property_info")) res4 = yield call(getListingCoverSheetAPI, `${dealId}/property_info?agent_id=${dealAgentId}`)
    if (callAPI.includes("property_info")) res4 = yield call(getListingCoverSheetAPI, `${dealId}/get_property_information?agent_id=${dealAgentId}`)
    if (callAPI.includes("tc_info")) res5 = yield call(getListingCoverSheetAPI, `${dealId}/tc_info?agent_id=${dealAgentId}`)
    if (callAPI.includes("insert_vendor")) res6 = yield call(getListingCoverSheetAPI, `${dealId}/insert_vendor?agent_id=${dealAgentId}`)
    if (callAPI.includes("select_vendor")) res7 = yield call(getListingCoverSheetAPI, `${dealId}/select_vendor?agent_id=${dealAgentId}`)
    if (callAPI.includes("sign_post")) res8 = yield call(getListingCoverSheetAPI, `${dealId}/sign_post?agent_id=${dealAgentId}`)

    // if (isSuccess(res1)) {
    const response = {
      agentInfo: res1.data.response?.agents,
      sellerInfo: res2.data.response?.sellers,
      sourceTime: res3.data.response,
      propertyInfo: res4.data.response,
      tcInfo: res5.data.response,
      insertVendor: res6.data.response,
      selectVendor: res7.data.response?.vendors,
      signPost: res8.data.response,
    }
    if (response.agentInfo && response.agentInfo.length) {
      const primaryAgent = response.agentInfo.filter(ag => ag.is_primary === "Y" || ag.is_primary === 1)
      const secondaryAgent = response.agentInfo.filter(ag => ag.is_primary === "N" || ag.is_primary === 0)
      if (primaryAgent.length) {
        response.agentInfo = [
          ...primaryAgent,
          ...secondaryAgent,
        ]
      }
    }
    if (response.sellerInfo && response.sellerInfo.length) {
      const primaryAgent = response.sellerInfo.filter(ag => ag.is_primary === "Y" || ag.is_primary === 1)
      const secondaryAgent = response.sellerInfo.filter(ag => ag.is_primary === "N" || ag.is_primary === 0)
      if (primaryAgent.length) {
        response.sellerInfo = [
          ...primaryAgent,
          ...secondaryAgent,
        ]
      }
    }
    yield put(BrokerDashboardActions.getListingCoverSheet.success(response))
    yield put(BrokerDashboardActions.setLoaderOnAgentInfo.call(false))
    // }
  } catch (error) {
    yield put(BrokerDashboardActions.getListingCoverSheet.failure(error))
  }
}

function* handleGetContractsDetails(action) {
  try {
    const dealId = action?.data?.dealId

    const res = yield call(getContractsDetailsAPI, dealId)

    if (isSuccess(res)) {
      yield put(BrokerDashboardActions.getContractsDetails.success(res.data && res.data.response))
    }
    // }
  } catch (error) {
    yield put(BrokerDashboardActions.getContractsDetails.failure(error))
  }
}

//listing cover sheet
function* handleCreateListingCoverSheet(action) {
  try {
    const {
      payload,
      callAPI,
      nextRoute,
      history,
      callback,
      dealAgentId,
    } = action.data
    const res = yield call(createListingCoverSheetAPI, { ...payload, agent_id: dealAgentId })
    if (isSuccess(res)) {
      let dealIdToSend = null
      //here
      if (res.data.response.listing_id) {
        dealIdToSend = res.data.response.listing_id
      } else if (res.data.response) {
        dealIdToSend = parseInt(res.data.response, 10)
      }
      yield put(BrokerDashboardActions.createListingCoverSheet.success(res.data && res.data.response))
      //here
      yield put(BrokerDashboardActions.getListingCoverSheet.request({ listing_id: dealIdToSend, callAPI, dealAgentId }))
      // yield put(createListingCoverSheet.success({}))
      yield put(BrokerDashboardActions.setDealToView.call(dealIdToSend))
      yield put(BrokerDashboardActions.setDealAgentId.call(dealAgentId))
      if (callback) {
        callback(dealIdToSend)
      }
      history.push(nextRoute)
    }
  } catch (e) {
    yield put(BrokerDashboardActions.createListingCoverSheet.failure(e))
  }
}

function* handleUpdateListingCoverSheet(action) {
  try {
    const {
      payload,
      dealId,
      callAPI,
      nextRoute,
      history,
      callback,
      dealAgentId,
    } = action.data
    yield put(BrokerDashboardActions.setLoaderOnAgentInfo.call(true))
    const res = yield call(updateListingCoverSheetAPI, { payload: { ...payload, agent_id: dealAgentId }, dealId })
    if (isSuccess(res)) {
      yield put(BrokerDashboardActions.updateListingCoverSheet.success(res.data && res.data.response))
      yield put(BrokerDashboardActions.getListingCoverSheet.request({ dealId, callAPI, dealAgentId }))
      // yield put(updateListingCoverSheet.success({}))
      history.push(nextRoute)
      if (callback) {
        // callback()
      }
    }
  } catch (e) {
    yield put(BrokerDashboardActions.updateListingCoverSheet.failure(e))
  }
}

function* handleGetListingType(action) {
  try {
    const {
      state,
    } = action.data
    const res = yield call(getListingTypeAPI, state)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getListingTypeAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getListingTypeAction.failure(error))
  }
}

function* handleFetchDealTimeline(action) {
  try {
    const { dealId } = action.data
    const res = yield call(fetchDealTimelineAPI, dealId)
    if (isSuccess(res)) {
      yield put(BrokerDashboardActions.fetchDealTimelineAction.success(res.data && res.data.response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.fetchDealTimelineAction.failure(error))
  }
}

function* handleUpdateDealTimeline(action) {
  try {
    const { dealId, payload, callback } = action.data
    const res = yield call(updateDealTimelineAPI, dealId, payload)
    if (isSuccess(res)) {
      yield put(BrokerDashboardActions.updateDealTimelineAction.success(res.data && res.data.response))
      callback()
    }
  } catch (error) {
    yield put(BrokerDashboardActions.updateDealTimelineAction.failure(error))
  }
}

function* handleCreateOffersDocusignTemplate(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    const res = yield call(createOffersDocusignTemplateAPI, payload)
    if (isSuccess(res)) {
      yield put(BrokerDashboardActions.createOffersDocusignTemplate.success(res.data && res.data.response))
      callback()
    }
  } catch (e) {
    yield put(BrokerDashboardActions.createOffersDocusignTemplate.failure(e))
  }
}

function* handleGetOffersDocusignTemplate() {
  try {
    console.log("CALLED---handleGetOffersDocusignTemplate")
    const res = yield call(getOffersDocusignTemplateAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getOffersDocusignTemplate.success(response))
      console.log("template response saga", response)
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getOffersDocusignTemplate.success(error))
  }
}

function* handleDeleteOffersDocusignTemplate(action) {
  try {
    const {
      id,
      callback,
    } = action.data
    const res = yield call(deleteOffersDocusignTemplateAPI, id)
    if (isSuccess(res)) {
      yield put(BrokerDashboardActions.deleteOffersDocusignTemplate.success(res.data && res.data.response))
      callback()
    }
  } catch (e) {
    yield put(BrokerDashboardActions.deleteOffersDocusignTemplate.failure(e))
  }
}

function* handleGetOffersTotalCommonTemplate() {
  try {
    const res = yield call(getOffersTotalCommonTemplateAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getOffersTotalCommonTemplate.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getOffersTotalCommonTemplate.success(error))
  }
}

function* handleGetAgentBrokerageInfoMetadata() {
  try {
    const res = yield call(getAgentBrokerageInfoMetadataAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getAgentBrokerageInfoMetadataAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getAgentBrokerageInfoMetadataAction.failure(error))
  }
}

function* handleInviteWithoutSubscription(action) {
  try {
    const {
      payload,
      fetchIca,
      agentId,
      history,
      isUploadingICA,
    } = action.data || {}
    yield put(BrokerDashboardActions.toggleInviteWithoutSubscriptionModalAction.call({
      bool: false,
      data: null,
    }))

    const res = yield call(inviteWithoutSubscriptionAPI, payload, agentId, fetchIca)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.inviteWithoutSubscriptionAction.success(response))
      yield put(BrokerDashboardActions.toggleICADetailsModalAction.call(false))

      //call fetch ica docs
      if (!isUploadingICA) {
        yield put(BrokerDashboardActions.fetchICAListingsAction.request({
          agentId,
        }))
      } else {
        Modal.success({
          content: "Upload custom ICA now.",
        })
      }

      if (history) {
        history.push(`/broker/dashboard/${agentId}/ica`)
      }
    }
  } catch (error) {
    Modal.error({
      content: "Something went wrong, please try again later.",
    })
    yield put(BrokerDashboardActions.inviteWithoutSubscriptionAction.failure(error))
  }
}

function* handleFetchTeamAgreements(action) {
  try {
    const {
      type,
      agentId,
      teamId,
    } = action.data || {}
    const res = yield call(fetchTeamAgreementsAPI, type, agentId, teamId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.fetchTeamAgreementsAction.success(response))
      yield put(BrokerDashboardActions.toggleMissingTeamMessageAction.call(""))
    }
  } catch (error) {
    const {
      history,
      agentId,
      teamId,
    } = action.data || {}
    if (error.status === 404) {
      yield put(BrokerDashboardActions.toggleMissingTeamMessageAction.call("No Team Found, Please assign a team to the corresponding Team Lead"))
      Modal.error({
        content: "Please select a Team Name or Create a new team from the sidebar if team doesn’t exist.",
      })
      notification.error({
        message: "Team name is missing.",
        description: "Please select a Team Name or Create a new team from the side bar if team doesn’t exist.",
        duration: 100,
        placement: "top",
      })

      if (!teamId) {
        history.push(`/broker/dashboard/${agentId}/subscription`)
      } else {
        history.push(`/broker/teams/edit/${teamId}`)
      }
    }
    yield put(BrokerDashboardActions.fetchTeamAgreementsAction.failure(error))
  }
}

function* handleSaveStripeToken(action) {
  try {
    const { token, callback } = action.data
    const agentId = yield select(state => state.brokerDashboard.agentDetailsResponse.data.id)
    const secureId = yield getSecureIdForAgent(agentId)
    const res = yield call(saveStripeTokenAPI, { token }, secureId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.saveStripeTokenAction.success(response))
      //createToast("Saving New Card Details.")
      if (callback) {
        callback()
      }
    }
  } catch (error) {
    yield put(BrokerDashboardActions.saveStripeTokenAction.failure(error))
  }
}

function* handleFetchTentativeBrokerageDetails(action) {
  try {
    const {
      agentId,
    } = action.data || {}
    const res = yield call(getTentativeBrokerageSubscriptionDetailsAPI, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getTentativeBrokerageSubscriptionDetailsAction.success(response))

      //to view add ons & risk management options
      if (response && response.basePlan && response.basePlan.plan_id) {
        yield put(BrokerDashboardActions.getPlanbenefitsAction.request({
          planId: response.basePlan.plan_id,
          agentId,
        }))
      }
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getTentativeBrokerageSubscriptionDetailsAction.failure(error))
  }
}

function* handleFetchASMAndRecuiters(action) {
  try {
    const {
      agentId,
    } = action.data
    const res = yield call(fetchRecruiterAndAsmInfoAPI, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.fetchRecruiterAndAsmInfoAction.success(response))
      yield put(BrokerDashboardActions.fetchAgentAsmRecruiterInfoAction.request({
        agentId,
      }))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.fetchRecruiterAndAsmInfoAction.failure(error))
  }
}

function* handleUpdateASMAndRecruiters(action) {
  try {
    const {
      agentId,
      payload,
    } = action.data
    const res = yield call(updateRecruiterAndAsmInfoAPI, agentId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.updateRecruiterAndAsmInfoAction.success(response))
      yield put(BrokerDashboardActions.fetchAgentAsmRecruiterInfoAction.request({
        agentId,
      }))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.updateRecruiterAndAsmInfoAction.failure(error))
  }
}

function* handleAgentRecruiterAndASM(action) {
  try {
    const {
      agentId,
    } = action.data
    const res = yield call(fetchAgentAsmRecruiterInfoAPI, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.fetchAgentAsmRecruiterInfoAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.fetchAgentAsmRecruiterInfoAction.failure(error))
  }
}

function* handleFileUploadForICA(action) {
  try {
    const {
      agentId,
      payload,
    } = action.data
    const res = yield call(uploadTeamAgreementAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.uploadICAFileAction.success(response))
      yield put(BrokerDashboardActions.toggleUploadModeForICAAction.call(false))
      yield put(BrokerDashboardActions.toggleFullySignedICAUploadUIAction.call(false))
      yield put(BrokerDashboardActions.fetchICAListingsAction.request({
        agentId,
      }))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.uploadICAFileAction.failure(error))
  }
}

function* handleSendOnboardingInvite(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(sendOnboardingEmailAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.sendOnboardingEmailAction.success(response))
      //alert("Onboarding Invite Sent!")
    }
  } catch (error) {
    yield put(BrokerDashboardActions.sendOnboardingEmailAction.failure(error))
  }
}

function* fetchOnboardingURL(action) {
  try {
    const {
      agentId,
    } = action.data
    const res = yield call(getOnboardingURLAPI, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.copyOnboardingURLAction.success(response))
      if (response) {
        notify.show("Copied Invite URL!", 5000)
        copyToClipBoard(response.url)
      }
      //copy function
    }
  } catch (error) {
    yield put(BrokerDashboardActions.copyOnboardingURLAction.failure(error))
  }
}

//handleFetchFullySignedICA
function* handleFetchFullySignedICA(action) {
  try {
    const {
      agentId,
    } = action.data
    const res = yield call(fetchFullySignedICAAPI, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.fetchFullySignedICAAction.success(response))

      if (response === null || !response || Object.keys(response).length <= 0) {
        yield put(BrokerDashboardActions.fetchICAListingsAction.request({
          agentId,
        }))
      } else {
        yield put(BrokerDashboardActions.disableOtherOptionsICAActions.call(true))
      }
    }
  } catch (error) {
    yield put(BrokerDashboardActions.fetchFullySignedICAAction.failure(error))
  }
}

function* handleUploadFullySignedICA(action) {
  try {
    const {
      agentId,
      payload,
    } = action.data
    const res = yield call(uploadFullySignedICAAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.uploadFullySignedICAAction.success(response))
      yield put(BrokerDashboardActions.toggleFullySignedICAUploadUIAction.call(false))
      yield put(BrokerDashboardActions.fetchFullySignedICAAction.request({
        agentId,
      }))
      yield put(BrokerDashboardActions.disableOtherOptionsICAActions.call(true))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.uploadFullySignedICAAction.failure(error))
  }
}

function* handleSaveTeamAgreement(action) {
  try {
    const {
      teamId,
      payload,
    } = action.data
    const res = yield call(saveTeamAgreementAPI, teamId, payload)
    if (isSuccess(res)) {
      Modal.success({
        content: "Team agreement preference saved",
      })
      const { response } = res.data
      yield put(BrokerDashboardActions.saveTeamAgreementAction.success(response))
      yield put(BrokerDashboardActions.getRARTeamListAction.request({
        skip: 0,
        limit: 30,
      }))
    }
  } catch (error) {
    Modal.error({
      content: "Something went wrong, please try again later.",
    })
    yield put(BrokerDashboardActions.saveTeamAgreementAction.failure(error))
  }
}

function* handleTriggerTemplateView(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(triggerTemplateEditViewAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.triggerTemplateEditViewAction.success(response))
      const {
        url,
      } = response || {}

      if (url) {
        root.open(url, "_blank")
      }
    }
  } catch (error) {
    Modal.error({
      content: "Something Went Wrong...",
    })
    yield put(BrokerDashboardActions.triggerTemplateEditViewAction.failure(error))
  }
}

function* handleFetchUrlForDownloadingTemplate(action) {
  try {
    const {
      templateId,
      agentId,
      teamId,
    } = action.data
    const secureId = yield getSecureIdForAgent(agentId)

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_BASE_URL}/v1.0/brokerage-ica/templates/documents?templateId=${templateId}&agentId=${agentId}&teamId=${teamId}`,
      headers: { "secure-id": secureId },
      responseType: "blob",
    }

    axios(config)
      .then((response) => {
        notification.success({
          message: "File downloaded",
          description: "Please view the file on different tab.",
          duration: 5,
          placement: "top",
        })
        const file = URL.createObjectURL(response.data)
        const link = document.createElement("a")
        link.setAttribute("href", file)
        link.setAttribute("download", "team_agreement.pdf")
        document.body.appendChild(link)
        setTimeout(() => {
          root.open(file, "_blank")
          link.click()
        }, 1000)
      })
      .catch((error) => {
        console.log(error)
      })

    yield delay(1300)

    yield put(BrokerDashboardActions.getTemplateDownloadURLAction.success())
  } catch (error) {
    Modal.error({
      content: "Something went wrong..",
    })
    yield put(BrokerDashboardActions.getTemplateDownloadURLAction.failure(error))
  }
}

function* handleFetchICADocuments(action) {
  try {
    const {
      agentId,
    } = action.data

    const res = yield call(fetchICAListingsAPI, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.fetchICAListingsAction.success(response))
      if (response && response[0]
        && response[0].status
        && (response[0].status === "agent_signature_pending" || response[0].status === "agent_signed")) {
        if (response[0].status === "agent_signature_pending") {
          yield put(BrokerDashboardActions.showUpdateLiveDotAction.call(true))
          Modal.info({
            content: "Agent signatures are pending, if you have shared the onboarding link/email to the corresponding agent, please wait until the agent has signed for the status of the ICA to be updated as 'Signed'.",
          })
        }

        if (response[0].status === "agent_signed") {
          Modal.info({
            content: "Agent has successfully signed the ICA.",
          })
          yield put(BrokerDashboardActions.showUpdateLiveDotAction.call(false))
        }
        yield put(BrokerDashboardActions.disableOtherOptionsICAActions.call(true))
      }
    }
  } catch (error) {
    yield put(BrokerDashboardActions.fetchICAListingsAction.failure(error))
  }
}

function* handleFetchAgentICADetails(action) {
  try {
    const {
      agentId,
    } = action.data || {}
    const res = yield call(fetchAgentICADetailsAPI, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.fetchAgentICADetailsAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.fetchAgentICADetailsAction.failure(error))
  }
}

function* handleSenderViewForICA(action) {
  try {
    const {
      payload,
    } = action.data || {}
    const res = yield call(triggerDocusignSenderViewAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.triggerSenderViewDocuSignAction.success(response))
      const {
        url,
      } = response || {}

      if (url) {
        root.open(url, "_blank")
      }
    }
  } catch (error) {
    yield put(BrokerDashboardActions.triggerSenderViewDocuSignAction.failure(error))
  }
}

function* handleRecieverViewForICA(action) {
  try {
    const {
      payload,
    } = action.data || {}
    const res = yield call(triggerDocusignRecieverViewAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.triggerRecieverViewDocuSignAction.success(response))
      const {
        url,
      } = response || {}

      if (url) {
        yield put(BrokerDashboardActions.showUpdateLiveDotAction.call(true))
        Modal.info({
          content: "If you have reviewed and signed the documents, kindly proceed with sharing the onboarding link with the Agent.",
        })
        root.open(url, "_blank")
      }
    }
  } catch (error) {
    Modal.error({
      content: "Something went wrong, please try again later",
    })
    yield put(BrokerDashboardActions.triggerRecieverViewDocuSignAction.failure(error))
  }
}

function* handleMergeReviewSendDocument(action) {
  try {
    const {
      agentId,
      payload,
    } = action.data
    const res = yield call(mergeReviewSendAPI, payload, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.mergeReviewAndSendDocumentsAction.success(response))
      //on successful merging

      yield put(BrokerDashboardActions.fetchICAListingsAction.request({
        agentId,
      }))
    }
  } catch (error) {
    Modal.error({
      content: "Something went wrong, please try again later.",
    })
    yield put(BrokerDashboardActions.mergeReviewAndSendDocumentsAction.failure(error))
  }
}

function* handleUploadICA(action) {
  try {
    const {
      agentId,
      payload,
    } = action.data
    const res = yield call(uploadICAAPI, payload, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.uploadICAAction.success(response))
      yield put(BrokerDashboardActions.toggleUploadModeForICAAction.call(false))
      yield put(BrokerDashboardActions.toggleFullySignedICAUploadUIAction.call(false))
      yield put(BrokerDashboardActions.fetchICAListingsAction.request({
        agentId,
      }))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.uploadICAAction.failure(error))
  }
}

function* handleICADocumentStatusUpdate() {
  try {
    const res = yield call(modifyICAStatusAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.updateICADocumentStatusAction.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.updateICADocumentStatusAction.failure(error))
  }
}

function* handleUploadTeamAgreement(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    const res = yield call(uploadTeamAgreementAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.uploadTeamAgreementAction.success(response))
      yield put(BrokerDashboardActions.toggleTeamAgreementTypeAction.call(1))
      yield put(BrokerDashboardActions.fetchTeamAgreementsAction.request({
        type: "team_agreement",
        agentId: payload.agentId,
      }))
      if (callback) {
        callback()
      }
    }
  } catch (error) {
    yield put(BrokerDashboardActions.uploadTeamAgreementAction.failure(error))
  }
}

function* handleCSVUploadToS3(action) {
  try {
    const {
      file,
      objName,
      fileName,
      bucketName,
      agentId,
      uploadType,
    } = action.data
    const signedRes = yield fetch(`https://us-central1-sturdy-chimera-767.cloudfunctions.net/file-uploader?bucket=${bucketName}&filename=${fileName}`)
    const signedResJson = yield signedRes.json()
    const myHeaders = new Headers({ "Content-Type": file.type })

    const res = yield fetch(signedResJson.url, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    })
    if (res.status === 200 && signedResJson.fileUrlAfterUpload) {
      yield put(BrokerDashboardActions.uploadCSVToS3Action.success(signedResJson.fileUrlAfterUpload))
      const s3url = signedResJson.fileUrlAfterUpload
      const s3file = s3url.split("/")
      const s3filename = s3file[s3file.length - 1]
      const url = `https://s3.amazonaws.com/files.radiusagent.com/agent-transactions/${s3filename}`
      const s3payload = {
        agentId,
        uploadType,
        url,
      }
      yield put(BrokerDashboardActions.uploadS3LocationAction.request({ payload: s3payload }))
      //yield call(handleS3Location, { data: { payload: s3payload } })
      yield put(BrokerDashboardActions.toggleModalViewAction.request(false))
    }
  } catch (e) {
    yield put(BrokerDashboardActions.uploadCSVToS3Action.failure(e))
  }
}

function* handleUploadW2Form(action) {
  try {
    const {
      agentId,
      file,
      fileName,
      bucketName,
      callback,
    } = action.data
    const signedRes = yield fetch(`https://us-central1-sturdy-chimera-767.cloudfunctions.net/file-uploader?bucket=${bucketName}&filename=${fileName}`)
    const signedResJson = yield signedRes.json()
    const myHeaders = new Headers({ "Content-Type": file.type })

    const res = yield fetch(signedResJson.url, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    })
    if (res.status === 200 && signedResJson.fileUrlAfterUpload) {
      const s3url = signedResJson.fileUrlAfterUpload
      // const s3file = s3url.split("/")
      // const s3filename = s3file[s3file.length - 1]
      // const url = `https://s3.amazonaws.com/files.radiusagent.com/s-w2-documents/${s3filename}`
      const url = s3url
      const payload = {
        agentId,
        url,
      }
      const resUpload = yield call(uploadW2FormAPI, payload)
      if (isSuccess(resUpload)) {
        yield put(BrokerDashboardActions.uploadW2FormAction.success(resUpload.data && resUpload.data.response))
        message.success("File uploaded !")
        if (callback) callback()
        //window.location.reload()
      }
      yield put(BrokerDashboardActions.toggleModalViewAction.request(false))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.uploadW2FormAction.failure(error))
  }
}

function* handleS3Location(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(uploadS3LocationAPI, payload)
    if (isSuccess(res)) {
      yield put(BrokerDashboardActions.uploadS3LocationAction.success(res.data && res.data.response))
      message.success("File uploaded !")
      window.location.reload()
    }
  } catch (e) {
    yield put(BrokerDashboardActions.uploadS3LocationAction.failure(e))
  }
}

function* handleTriggerVoidDelete(action) {
  try {
    const {
      payload,
      agentId,
    } = action.data
    const res = yield call(triggerVoidAndDeleteAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.triggerVoidAndDeleteAction.success(response))
      Modal.success({
        content: "ICA has been voided and deleted",
      })
      yield put(BrokerDashboardActions.fetchICAListingsAction.request({
        agentId,
      }))
      yield put(BrokerDashboardActions.showUpdateLiveDotAction.call(false))
      yield put(BrokerDashboardActions.disableOtherOptionsICAActions.call(false))
      yield put(BrokerDashboardActions.toggleVoidAndDeleteModalAction.call(false))
    }
  } catch (e) {
    Modal.error({
      content: "Something went wrong, please try again later.",
    })
    yield put(BrokerDashboardActions.triggerVoidAndDeleteAction.failure(e))
  }
}

function* handleGetListingTotalCommonTemplate() {
  try {
    const res = yield call(getListingTotalCommonTemplateAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.getListingTotalCommonTemplate.success(response))
    }
  } catch (error) {
    yield put(BrokerDashboardActions.getListingTotalCommonTemplate.success(error))
  }
}

function* handleFullyLoadedICADeletion(action) {
  try {
    const {
      payload,
    } = action.data || {}
    const res = yield call(triggerDeleteFullyLoadedICAAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.triggerDeleteFullyLoadedICAAction.success(response))
      Modal.success({
        content: "The uploaded ISA document has been successfully deleted.",
      })
      yield put(BrokerDashboardActions.fetchFullySignedICAAction.request({
        agentId: payload.agentId,
      }))
      yield put(BrokerDashboardActions.disableOtherOptionsICAActions.call(false))
      yield put(BrokerDashboardActions.fetchICAListingsAction.request({
        agentId: payload.agentId,
      }))
    }
  } catch (e) {
    Modal.error({
      content: "Something went wrong, please try again later.",
    })
    yield put(BrokerDashboardActions.triggerDeleteFullyLoadedICAAction.failure(e))
  }
}

function* handleUpdateSubscriptionDate(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(updateSubscriptionDateAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(BrokerDashboardActions.updateSubscriptionDateAction.success(response))
      Modal.success({
        content: "Subscription date updated.",
      })
    }
  } catch (error) {
    yield put(BrokerDashboardActions.updateSubscriptionDateAction.success(error))
  }
}

export default function* main() {
  yield takeLatest(BrokerDashboardActions.getPendingRequestsAction.REQUEST, handleGetPendingRequests)
  yield takeLatest(BrokerDashboardActions.changeLinkAction.REQUEST, handleLinkChange)
  yield takeLatest(BrokerDashboardActions.changeStatusRequestAction.REQUEST, handleStatusChange)
  yield takeLatest(BrokerDashboardActions.getRARTeamListAction.REQUEST, handleRarTeamList)
  yield takeLatest(BrokerDashboardActions.getBrokerTeamsOverviewAction.REQUEST, handleTeamsPageOverview)
  yield takeLatest(BrokerDashboardActions.getBrokerageTeamDownloadAction.REQUEST, handleTeamsPageDataDownload)
  yield takeLatest(BrokerDashboardActions.getAllDealsListingActions.REQUEST, handleGetDeals)
  yield takeLatest(BrokerDashboardActions.getAgentDetailsAction.REQUEST, handleGetAgent)
  yield takeLatest(BrokerDashboardActions.createDealAction.REQUEST, handleCreateDeal)
  yield takeLatest(BrokerDashboardActions.getPlanbenefitsAction.REQUEST, handleBenefits)
  yield takeLatest(BrokerDashboardActions.checkoutBrokerPlanAction.REQUEST, handleCheckout)
  yield takeLatest(BrokerDashboardActions.checkCouponAction.REQUEST, handleCheckCouponCode)
  yield takeLatest(BrokerDashboardActions.getUSStatesAction.REQUEST, handleGetUsStates)
  yield takeLatest(BrokerDashboardActions.changeStatesForAgentAction.REQUEST, handleSaveStates)
  yield takeLatest(BrokerDashboardActions.getAgentStatesActions.REQUEST, handleAgentStates)
  yield takeLatest(BrokerDashboardActions.getAssetsDashboardAction.REQUEST, handleAssetsDashboard)
  yield takeLatest(BrokerDashboardActions.createNewGlobalAssetAction.REQUEST, handleCreateNewAssets)
  yield takeLatest(BrokerDashboardActions.getAgentAssetsActions.REQUEST, handleGetAgentAssets)
  yield takeLatest(BrokerDashboardActions.updateAgentAssetsActions.REQUEST, handleUpdateAgentAssets)
  yield takeLatest(BrokerDashboardActions.uploadFilesAction.REQUEST, handleUploadFiles)
  yield takeLatest(BrokerDashboardActions.uploadDocsToBucketAction.REQUEST, handleFileUploadToBucketOrientation)
  yield takeLatest(BrokerDashboardActions.uploadDocsForListingAction.REQUEST, handleImageUploadForListing)
  yield takeLatest(BrokerDashboardActions.uploadDocsToBucketBrandingAction.REQUEST, handleFileUploadToBucketBranding)
  yield throttle(1000, BrokerDashboardActions.getAgentForRosterPageAction.REQUEST, handleRosterPage)
  yield takeLatest(BrokerDashboardActions.getAgentYearEndWrapAction.REQUEST, handleGetAgentYearEndWrap)
  yield throttle(1000, BrokerDashboardActions.getAgentPipelinePageAction.REQUEST, handleAgentPipelinePage)
  yield takeLatest(BrokerDashboardActions.getReferralSourcesAction.REQUEST, handleReferralSources)
  yield takeLatest(BrokerDashboardActions.cancelSubscriptionAction.REQUEST, cancelSubscription)
  yield takeLatest(BrokerDashboardActions.getSubsribedAddonsAction.REQUEST, handleSubscribedAddons)
  yield takeLatest(BrokerDashboardActions.deleteGlobalAssetAction.REQUEST, handleGlobalAssetDelete)
  yield takeLatest(BrokerDashboardActions.updateSubscriptionAction.REQUEST, handleUpdateSubscription)
  yield takeLatest(BrokerDashboardActions.getAgentCardDetailsAction.REQUEST, handleGetAgentCardDetails)
  yield takeLatest(BrokerDashboardActions.deleteGlobalAssetAction.REQUEST, handleGlobalAssetDelete)
  yield takeLatest(BrokerDashboardActions.searchClientByText.REQUEST, handleClientSearch)
  yield takeLatest(BrokerDashboardActions.getSecureIdForTCAction.REQUEST, handleGetAgentSecureId)
  yield takeLatest(BrokerDashboardActions.getFileForTCAction.REQUEST, handleTCFiles)
  yield takeLatest(BrokerDashboardActions.updateUserSettingsAction.REQUEST, handleUpdateUserSettings)
  yield takeLatest(BrokerDashboardActions.getAgentTypesAction.REQUEST, handleAgentTypesList)
  yield takeLatest(BrokerDashboardActions.getUserAgentTypeAction.REQUEST, handleUserAgentTypeList)
  yield takeLatest(BrokerDashboardActions.changeUserAgentTypeAction.REQUEST, handleChangeUserAgentTypeList)
  yield takeLatest(BrokerDashboardActions.saveStepsForProductAction.REQUEST, handleSaveSteps)
  yield takeLatest(BrokerDashboardActions.getCurrentCitiesServedAction.REQUEST, handleFetchCitiesServed)
  yield takeLatest(BrokerDashboardActions.getNeighbourHoodFromCitiesAction.REQUEST, handleFetchNeighbourhoodsFromCity)
  yield takeLatest(BrokerDashboardActions.saveAgentServedNeighbourhoodAction.REQUEST, handleSaveNeighbourhoodForAgent)
  yield takeLatest(BrokerDashboardActions.deleteNeighbourhoodAction.REQUEST, handleDeleteNeighbourhood)
  yield takeLatest(BrokerDashboardActions.tcOverviewAction.REQUEST, handleTCPageOverview)
  yield takeLatest(BrokerDashboardActions.tcDataDownloadAction.REQUEST, handleDownloadTcData)
  yield takeLatest(BrokerDashboardActions.rosterPageDataDownloadAction.REQUEST, handleRosterPageDataDownload)
  yield takeLatest(BrokerDashboardActions.updateStarredStatusInStateAction.type, handleStarredStatus)
  // yield takeLatest(BrokerDashboardActions.handleAssignTeamOrTeamLeadAction.REQUEST,handleAssignTeamOrTeamLead)
  yield takeLatest(BrokerDashboardActions.deleteRARTeamAction.REQUEST, handleRARTeamDelete)
  yield takeLatest(BrokerDashboardActions.getResourcesListAction.REQUEST, handleGetAllResources)
  yield takeLatest(BrokerDashboardActions.createResourceAction.REQUEST, handleCreateResource)
  yield takeLatest(BrokerDashboardActions.updateResourcesAction.REQUEST, handleResoucrUpdate)
  yield takeLatest(BrokerDashboardActions.deleteResourcesAction.REQUEST, handleDeleteResource)
  yield takeLatest(BrokerDashboardActions.sortTCListAction.type, handleTCSort)
  yield takeLatest(BrokerDashboardActions.updateCommsionDemandAction.REQUEST, handleCommissionDemandUpdate)
  yield takeLatest(BrokerDashboardActions.deleteCommissionDemandActions.REQUEST, hableDeleteCommissionDemand)
  yield takeLatest(BrokerDashboardActions.getTCListAction.REQUEST, handleTCList)
  yield takeLatest(BrokerDashboardActions.getDealStatusTimelineAction.REQUEST, handleDealStatusTimeline)
  yield takeLatest(BrokerDashboardActions.updateTCAgentAction.REQUEST, handleUpdateTcAgent)
  yield takeLatest(BrokerDashboardActions.updateAgentOptedTCAction.REQUEST, handleUpdateAgentAssignedTC)
  yield takeLatest(BrokerDashboardActions.getTCAgentAssignedAction.REQUEST, handleFetchTCAgentAssigned)
  yield takeLatest(BrokerDashboardActions.sendSlackDealStatusChangeAction.REQUEST, handleSlackDealStatusChange)
  yield takeLatest(BrokerDashboardActions.getOrientationInfoAction.REQUEST, handleOrientationInfo)
  yield takeLatest(BrokerDashboardActions.updateOrientationInfoAction.REQUEST, handleUpdateOrientationInfo)
  yield takeLatest(BrokerDashboardActions.createOrientationInfoAction.REQUEST, handleCreateOrientationInfo)
  yield takeLatest(BrokerDashboardActions.getBrandingInfoAction.REQUEST, handleBrandingInfo)
  yield takeLatest(BrokerDashboardActions.getBrandingAssetsAction.REQUEST, handleBrandingAssets)
  yield takeLatest(BrokerDashboardActions.createBrandingInfoAction.REQUEST, handleCreateBrandingInfo)
  yield takeLatest(BrokerDashboardActions.updateBrandingInfoAction.REQUEST, handleUpdateBrandingInfo)
  yield takeLatest(BrokerDashboardActions.createBrandingAssetsAction.REQUEST, handleCreateBrandingAssets)
  yield takeLatest(BrokerDashboardActions.updateBrandingAssetsAction.REQUEST, handleUpdateBrandingAssets)
  yield takeLatest(BrokerDashboardActions.getSetupProfileAction.REQUEST, handleSetupProfileInfo)
  yield takeLatest(BrokerDashboardActions.updateSetupProfileAction.REQUEST, handleUpdateSetupProfile)

  yield takeLatest(BrokerDashboardActions.getListingCoverSheet.REQUEST, handleGetListingCoverSheet)
  yield takeLatest(BrokerDashboardActions.getContractsDetails.REQUEST, handleGetContractsDetails)
  yield takeLatest(BrokerDashboardActions.createListingCoverSheet.REQUEST, handleCreateListingCoverSheet)
  yield takeLatest(BrokerDashboardActions.updateListingCoverSheet.REQUEST, handleUpdateListingCoverSheet)
  yield takeLatest(BrokerDashboardActions.getListingTypeAction.REQUEST, handleGetListingType)
  yield takeLatest(BrokerDashboardActions.uploadSellerProfileImageAction.REQUEST, handleUploadSellerProfileImage)
  yield takeLatest(BrokerDashboardActions.getVendorDetailsAction.REQUEST, handleGetVendorDetails)
  yield takeLatest(BrokerDashboardActions.createVendorDetailsAction.REQUEST, handleCreateVendorDetails)
  yield takeLatest(BrokerDashboardActions.updateVendorDetailsAction.REQUEST, handleUpdateVendorDetails)
  yield takeLatest(BrokerDashboardActions.getContractStatusAction.REQUEST, handleGetContractStatus)
  yield takeLatest(BrokerDashboardActions.selectVendorDetailsAction.REQUEST, handleSelectVendorDetails)
  yield takeLatest(BrokerDashboardActions.saveFileTypeChangeAction.REQUEST, handleSaveFileTypeChange)
  yield takeLatest(BrokerDashboardActions.createAllListingImagesAction.REQUEST, handleCreateAllListingImages)
  yield takeLatest(BrokerDashboardActions.createDocusignTemplateAction.REQUEST, handleCreateDocusignTemplate)
  yield takeLatest(BrokerDashboardActions.getDocusignTemplateAction.REQUEST, handleGetDocusignTemplate)
  yield takeLatest(BrokerDashboardActions.deleteDocusignTemplateAction.REQUEST, handleDeleteDocusignTemplate)
  yield takeLatest(BrokerDashboardActions.fetchDealTimelineAction.REQUEST, handleFetchDealTimeline)
  yield takeLatest(BrokerDashboardActions.updateDealTimelineAction.REQUEST, handleUpdateDealTimeline)

  yield takeLatest(BrokerDashboardActions.createOffersDocusignTemplate.REQUEST, handleCreateOffersDocusignTemplate)
  yield takeLatest(BrokerDashboardActions.getOffersDocusignTemplate.REQUEST, handleGetOffersDocusignTemplate)
  yield takeLatest(BrokerDashboardActions.deleteOffersDocusignTemplate.REQUEST, handleDeleteOffersDocusignTemplate)
  yield takeLatest(BrokerDashboardActions.getOffersTotalCommonTemplate.REQUEST, handleGetOffersTotalCommonTemplate)
  yield takeLatest(BrokerDashboardActions.getAgentBrokerageInfoAction.REQUEST, handleGetAgentBrokerageInfo)
  yield takeLatest(BrokerDashboardActions.postAgentBrokerageInfoAction.REQUEST, handlePostAgentBrokerageInfo)
  yield takeLatest(BrokerDashboardActions.updateAgentBrokerageInfoAction.REQUEST, handleUpdateAgentBrokerageInfo)
  yield takeLatest(BrokerDashboardActions.getAgentBrokerageInfoMetadataAction.REQUEST, handleGetAgentBrokerageInfoMetadata)
  yield takeLatest(BrokerDashboardActions.checkoutBrokerPlanV2Action.REQUEST, handleCheckoutV2)
  yield takeLatest(BrokerDashboardActions.updateSubscriptionV2Action.REQUEST, handleUpdateSubscriptionV2)
  yield takeLatest(BrokerDashboardActions.fetchICAListingsAction.REQUEST, handleFetchICADocuments)
  yield takeLatest(BrokerDashboardActions.fetchAgentICADetailsAction.REQUEST, handleFetchAgentICADetails)
  yield takeLatest(BrokerDashboardActions.triggerSenderViewDocuSignAction.REQUEST, handleSenderViewForICA)
  yield takeLatest(BrokerDashboardActions.triggerRecieverViewDocuSignAction.REQUEST, handleRecieverViewForICA)
  yield takeLatest(BrokerDashboardActions.updateICADocumentStatusAction.REQUEST, handleICADocumentStatusUpdate)
  yield takeLatest(BrokerDashboardActions.mergeReviewAndSendDocumentsAction.REQUEST, handleMergeReviewSendDocument)
  yield takeLatest(BrokerDashboardActions.uploadICAAction.REQUEST, handleUploadICA)
  yield takeLatest(BrokerDashboardActions.uploadTeamAgreementAction.REQUEST, handleUploadTeamAgreement)
  yield takeLatest(BrokerDashboardActions.inviteWithoutSubscriptionAction.REQUEST, handleInviteWithoutSubscription)
  yield takeLatest(BrokerDashboardActions.fetchTeamAgreementsAction.REQUEST, handleFetchTeamAgreements)
  yield takeLatest(BrokerDashboardActions.saveStripeTokenAction.REQUEST, handleSaveStripeToken)
  yield takeLatest(BrokerDashboardActions.getTentativeBrokerageSubscriptionDetailsAction.REQUEST, handleFetchTentativeBrokerageDetails)
  yield takeLatest(BrokerDashboardActions.fetchRecruiterAndAsmInfoAction.REQUEST, handleFetchASMAndRecuiters)
  yield takeLatest(BrokerDashboardActions.updateRecruiterAndAsmInfoAction.REQUEST, handleUpdateASMAndRecruiters)
  yield takeLatest(BrokerDashboardActions.fetchAgentAsmRecruiterInfoAction.REQUEST, handleAgentRecruiterAndASM)
  //yield takeLatest(BrokerDashboardActions.uploadICAFileAction.REQUEST, handleFileUploadForICA)
  yield takeLatest(BrokerDashboardActions.sendOnboardingEmailAction.REQUEST, handleSendOnboardingInvite)
  yield takeLatest(BrokerDashboardActions.copyOnboardingURLAction.REQUEST, fetchOnboardingURL)
  yield takeLatest(BrokerDashboardActions.fetchFullySignedICAAction.REQUEST, handleFetchFullySignedICA)
  yield takeLatest(BrokerDashboardActions.uploadFullySignedICAAction.REQUEST, handleUploadFullySignedICA)
  yield takeLatest(BrokerDashboardActions.saveTeamAgreementAction.REQUEST, handleSaveTeamAgreement)
  yield takeLatest(BrokerDashboardActions.triggerTemplateEditViewAction.REQUEST, handleTriggerTemplateView)
  yield takeLatest(BrokerDashboardActions.getTemplateDownloadURLAction.REQUEST, handleFetchUrlForDownloadingTemplate)
  yield takeLatest(BrokerDashboardActions.uploadCSVToS3Action.REQUEST, handleCSVUploadToS3)
  yield takeLatest(BrokerDashboardActions.uploadS3LocationAction.REQUEST, handleS3Location)
  yield takeLatest(BrokerDashboardActions.triggerDeleteFullyLoadedICAAction.REQUEST, handleFullyLoadedICADeletion)

  //saveing
  yield takeLatest(BrokerDashboardActions.saveListingStepsAction.REQUEST, handleSaveStepsListings)
  yield takeLatest(BrokerDashboardActions.tcOverviewListingAction.REQUEST, handleTCPageListingOverview)
  yield takeLatest(BrokerDashboardActions.triggerVoidAndDeleteAction.REQUEST, handleTriggerVoidDelete)
  yield takeLatest(BrokerDashboardActions.getListingTotalCommonTemplate.REQUEST, handleGetListingTotalCommonTemplate)
  yield takeLatest(BrokerDashboardActions.updateSubscriptionDateAction.REQUEST, handleUpdateSubscriptionDate)
  yield takeLatest(BrokerDashboardActions.uploadW2FormAction.REQUEST, handleUploadW2Form)
}
