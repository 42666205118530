import React from "react"
import styled from "styled-components"
import SubContainer from "container/BrokerDashboard/subscriptionContainer"
import {
  WhiteButton,
  BlueButton,
  Card,
  SectionTitle,
  SubTextDisclaimer,
  UnderlinedCTA,
} from "./commonStyles"
import {
  showConfirm,
} from "./ConfirmationModalUtil"

const ComponentWrap = styled.div``

const CTAFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`

const OuterWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const TextWrap = styled.div``

const ICAButtonBlock = ({
  openICAModal,
  toggleUploadModeForICA,
  toggleFullySignedICAUploadUI,
  disableICAModes,
  currentTypeICAFlow,
  setICAFlowType,
  fetchICAListingsResponse,
  icaUploadModeVisible,
  fullySignedICAUploadVisible,
}) => {
  const {
    data: isaDocuments,
    isFetching: fetchingDocuments,
  } = fetchICAListingsResponse || {}

  const areDocumentsAvailable = isaDocuments && isaDocuments.length > 0
  const docStatus = areDocumentsAvailable && isaDocuments[0].status
  const isAgentSignaturePending = docStatus && docStatus === "agent_signature_pending"

  const alertMessage = "ICA is already generated, do you wish to regenerate?"
  const otherMessage = "Performing this action is irreversible!"
  const uploadWarning = "ICA is already uploaded, do you wish to re-upload?"
  const fullyUploadWarning = `Performing this action means, ICA to be uploaded has been reviewed, 
  merged & signed by all parties, do you wish to proceed?`
  const agentICAPendingWarning = `The ICA documents have already been reviewed, merged and ready 
  to be sent to the agent, performing this action would replace current ICA with a custom Signed ICA!`

  const areDocumentsMerged = docStatus && (docStatus === "sales_review_pending" || docStatus === "agent_signature_pending" || docStatus === "agent_signed")

  const checkWhichToHighlight = (type) => {
    console.log(type, areDocumentsMerged, disableICAModes,isaDocuments, 'pragya')
    switch (type) {
      case "load_ica":
        if (areDocumentsMerged) {
          return !disableICAModes
            && ((areDocumentsAvailable && isaDocuments.length === 0 && isaDocuments[0]?.merged_envelopes?.length === 0 && !isaDocuments[0]?.merged_envelopes[0]?.document_name)
              || currentTypeICAFlow === "load_ica")
        }
        return !disableICAModes
        && ((areDocumentsAvailable && !isaDocuments[0].document_name)
        || currentTypeICAFlow === "load_ica")
      case "upload_ica":
        if (areDocumentsMerged) {
          return !disableICAModes
            && ((areDocumentsAvailable &&isaDocuments.length === 0 && isaDocuments[0]?.merged_envelopes?.length === 0 &&
             isaDocuments[0]?.merged_envelopes[0]?.document_name) || currentTypeICAFlow === "upload_ica")
        }
        return !disableICAModes
        && ((areDocumentsAvailable && isaDocuments.length === 0 && isaDocuments[0]?.merged_envelopes?.length === 0 &&
           isaDocuments[0].document_name) || currentTypeICAFlow === "upload_ica")
      default:
        return false
    }
  }

  return (
    <ComponentWrap>
      <Card>
        <TextWrap>
          <SectionTitle>Load/upload ICA</SectionTitle>
          <SubTextDisclaimer>
            <p>
              {isAgentSignaturePending ? "ICA has been reviewed & merged for agent signing, options below would be disabled. " : "Please click any one of the options"}
            </p>
          </SubTextDisclaimer>
        </TextWrap>
        <OuterWrap>
          <CTAFlexBox>
            <WhiteButton
              onClick={!areDocumentsAvailable ? openICAModal : () => { showConfirm(alertMessage, openICAModal, otherMessage) }}
              fontFamily="Montserrat"
              disabled={fetchingDocuments || disableICAModes}
              selected={checkWhichToHighlight("load_ica")}
              ATCFONT
            >
              Load Template ICA
            </WhiteButton>
            <WhiteButton
              ATCFONT
              disabled={fetchingDocuments || disableICAModes}
              onClick={() => {
                const postAction = (icaModalNotReqd = false) => {
                  if (!icaModalNotReqd) {
                    openICAModal()
                  }
                  toggleUploadModeForICA(!icaUploadModeVisible)
                  toggleFullySignedICAUploadUI(false)
                  setICAFlowType("upload_ica")
                }
                if (areDocumentsAvailable) {
                  if (icaUploadModeVisible) {
                    postAction(true)
                  } else {
                    showConfirm(uploadWarning, postAction, otherMessage)
                  }
                } else {
                  postAction()
                }
              }}
              selected={checkWhichToHighlight("upload_ica")}
            >
              {icaUploadModeVisible && areDocumentsAvailable ? "View Custom ICA" : "Load Custom ICA"}
            </WhiteButton>
          </CTAFlexBox>
          <CTAFlexBox>
            <UnderlinedCTA
              selected={currentTypeICAFlow === "fully_signed_ica"}
              ATCFONT
              onClick={() => {
                const postAction = () => {
                  toggleFullySignedICAUploadUI(!fullySignedICAUploadVisible)
                  toggleUploadModeForICA(false)
                  setICAFlowType("fully_signed_ica")
                }

                if (areDocumentsAvailable) {
                  if (!fullySignedICAUploadVisible) {
                    showConfirm(uploadWarning,
                      postAction,
                      isAgentSignaturePending ? agentICAPendingWarning
                        : fullyUploadWarning)
                  } else {
                    toggleFullySignedICAUploadUI(!fullySignedICAUploadVisible)
                    toggleUploadModeForICA(false)
                    setICAFlowType("")
                  }
                } else {
                  postAction()
                }
              }}
            >
              {fullySignedICAUploadVisible ? "Go back" : "Upload fully signed ICA"}
            </UnderlinedCTA>
          </CTAFlexBox>
        </OuterWrap>
      </Card>
    </ComponentWrap>
  )
}

export default SubContainer(ICAButtonBlock)
